import React, { useCallback } from 'react'
import { Text, View, ImageBackground, ImageSourcePropType } from 'react-native'
import { useDispatch } from 'react-redux'
import { Column, Row } from 'react-native-web-ui-components'
import { Content, Layout } from 'components/Layout'
import { ButtonLinkWhite } from 'components/ButtonLink'
import TextLink from 'components/TextLink'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { openLink } from 'pages/util'

const CONTENT = {
  guides: [
    {
      title: 'Ari Pacing Guides',
      subtitle: 'Ari Pacing Guide',
      entries: [
        { 
          text: 'Grades K-2',
          pdfURL: 'https://static.ozobot.com/assets/25-Ozobot-Ari-Grades-K-2-Pacing-Guide-PDF-v1.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1-PgqujMHeSiT1sNeOEoEeIKKo09Vg55y-KZe9Rn2UsQ/edit?usp=sharing&gid=119614911',
          image: require('images/pacing-guides/pacing-guide-ari-k2.png'),
        },
        { 
          text: 'Grades 3-4', 
          pdfURL: 'https://static.ozobot.com/assets/25-Ozobot-Ari-Grades-3-4-Pacing-Guide-PDF-v1.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1-PgqujMHeSiT1sNeOEoEeIKKo09Vg55y-KZe9Rn2UsQ/edit?usp=sharing&gid=1073234013',
          image: require('images/pacing-guides/pacing-guide-ari-3-4.png'),
        },
        { 
          text: 'Grade 5', 
          pdfURL: 'https://static.ozobot.com/assets/25-Ozobot-Ari-Grades-5-Pacing-Guide-PDF-v1.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1-PgqujMHeSiT1sNeOEoEeIKKo09Vg55y-KZe9Rn2UsQ/edit?usp=sharing&gid=1362360500',
          image: require('images/pacing-guides/pacing-guide-ari-5.png'),
        },
        { 
          text: 'Middle School', 
          pdfURL: 'https://static.ozobot.com/assets/25-Ozobot-Ari-Grades-Middle-School-Pacing-Guide-PDF-v1.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1-PgqujMHeSiT1sNeOEoEeIKKo09Vg55y-KZe9Rn2UsQ/edit?usp=sharing&gid=436346789',
          image: require('images/pacing-guides/pacing-guide-ari-middle.png'),
        },
        { 
          text: 'High School', 
          pdfURL: 'https://static.ozobot.com/assets/25-Ozobot-Ari-Grades-High-School-Pacing-Guide-PDF-v1.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1-PgqujMHeSiT1sNeOEoEeIKKo09Vg55y-KZe9Rn2UsQ/edit?usp=sharing&gid=513311127',
          image: require('images/pacing-guides/pacing-guide-ari-hs.png'),
        },
      ],
    },
    {
      title: 'Evo Pacing Guides',
      subtitle: 'Evo Pacing Guide',
      entries: [
        { 
          text: 'Kindergarten',
          pdfURL: 'https://static.ozobot.com/assets/2272f33b-24-ozobot-kindergarten-pacing-guide-pdf.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1_aCeGJ98PK2ZGPGGnDF3DhB7vC7TbgYEhjpLarhxzX4/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-k.png'),
        },
        { 
          text: 'Grade 1', 
          pdfURL: 'https://static.ozobot.com/assets/5a643523-24-ozobot-1st-grade-pacing-guide-pdf.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1-JakhZg26a_t3lP_Vc-msA1HtOYBXCJ1aDUNv6lt23k/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-1.png'),
        },
        { 
          text: 'Grade 2', 
          pdfURL: 'https://static.ozobot.com/assets/68904d83-24-ozobot-2nd-grade-pacing-guide-pdf.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1j-xJchM5JCz--sW-p7oYQxKTKllntyTGUbPwm_CtWV0/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-2.png'),
        },
        { 
          text: 'Grade 3', 
          pdfURL: 'https://static.ozobot.com/assets/96b32ff2-24-ozobot-3rd-grade-pacing-guide-pdf.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1XN685Xp4HkJ5a44uaYsbgFMEUe8fbEaLSHLQFxfnNZM/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-3.png'),
        },
        { 
          text: 'Grade 4', 
          pdfURL: 'https://static.ozobot.com/assets/bf3edaed-24-ozobot-4th-grade-pacing-guide-pdf.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1163XFiizeZnXNJEVcOVk6Hg1Io-lQ34-vWbK9RoEbcQ/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-4.png'),
        },
        { 
          text: 'Grade 5', 
          pdfURL: 'https://static.ozobot.com/assets/d35361f9-24-ozobot-5th-grade-pacing-guide-pdf.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1QrDlP1-fENRfqKlFOn294RXN9Hu0U1I2Ap90jf3IhDw/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-5.png'),
        },
        { 
          text: 'Middle School', 
          pdfURL: 'https://static.ozobot.com/assets/24-Ozobot-Middle-School-Pacing-Guides-Levels-1-3-PDF.pdf',
          docsURL: 'https://docs.google.com/spreadsheets/d/1v2UYNfBVKonNM2b_nxmk0DysyiCVVZlDQJ2QSNq_LVQ/edit?usp=sharing',
          image: require('images/pacing-guides/pacing-guide-evo-middle.png'),
        },
      ],
    },    
  ],
}

const PacingGuide = (props: { 
  text: string, 
  subtext: string, 
  pdfURL: string, 
  docsURL: string, 
  image: ImageSourcePropType,
  isDesktop: boolean, 
  browserWidowWidth: number,
}) => {
  const dispatch = useDispatch()
  const onDocs = useCallback(() => {
    openLink(props.docsURL, dispatch)
  }, [props.docsURL]) 

  const columnProps = props.isDesktop
    ? props.browserWidowWidth < 1803
      ? props.browserWidowWidth < 1342
        ? { xs: 12, sm: 6, md: 6 }
        : { xs: 12, sm: 6, md: 4 }
      : { xs: 12, sm: 6, md: 3 }
    : { xs: 12, sm: 6, md: 6 }

  return (
    <Column style={[ props.isDesktop ? s.px16 : null, s.mb32]} {...columnProps}>
      <View style={[
        s.br10,
        s.bTeal,
        s.bgGrayMoreLighter,
        s.bShadow,
        props.isDesktop ? s.w75p : s.w90p,
        s.mAuto,
      ]}>
        <Image source={props.image} style={{ 
          height: 180, 
          width: '100%', 
          borderTopLeftRadius: 10, 
          borderTopRightRadius: 10,
        }} resizeMode="cover" 
        />
        <View style={s.p20}>
          <View style={[]}>
            <Text style={[s.f16]}>
              <Text style={[s.textBold]}>{props.subtext + ' | '}</Text>
              {props.text}
            </Text>
          </View>
          <View style={[s.flexRow, s.alignCenter, s.mt32, s.justifyBetween]}>
            <TextLink style={[s.f16]} onPress={onDocs}>Google Sheets</TextLink>
            <ButtonLinkWhite buttonText="Download PDF" buttonLink={props.pdfURL} noSiteCheck={true}/>
          </View>
        </View>
      </View>
    </Column>
  )
}

const PacingGuideSection = (props: {
  title: string,
  subtitle: string,
  entries: { text: string, pdfURL: string, docsURL: string, image: ImageSourcePropType }[],
  isDesktop: boolean,
  browserWidowWidth: number,
}) => (<View style={[props.isDesktop && s.mx32]}>
  <Text style={[s.mb16, props.isDesktop ? s.f20 : s.f16, s.textBold, s.p16]}>{props.title}</Text>
  <View style={[s.w100p]}>
    <Row>
      {props.entries.map(((guide, i) => <PacingGuide 
        key={i} 
        subtext={props.subtitle} 
        isDesktop={props.isDesktop} 
        browserWidowWidth={props.browserWidowWidth}
        {...guide} 
      />))}
    </Row>
  </View>
</View>)

export default function HelpPacingGuides() {
  const { isMobile, isTablet, isDesktop, browserWidowWidth } = useDetectDeviceType();
  return (
    <Layout>
      <Content>
        <View style={[isDesktop ? s.w33p : ( isTablet ? { width: '60%' } : s.w90p )]}>
          <View>
            <ImageBackground source={require('images/pacing-guides/pacing-guides-top-header.png')} resizeMode="cover" imageStyle={isMobile ? { height: 50} : { height: 90 }}>
              { isMobile ?
                <Text style={[ s.f24, s.textBold, s.textItalic, s.textWhite, s.pl16, { paddingVertical: 10 } ]}>Ozobot Pacing Guides</Text>
                : 
                <Text style={[ s.f35, s.textBold, s.textItalic, s.textWhite, s.p24, s.textCenter, s.w500]}>Ozobot Pacing Guides</Text>
              }
            </ImageBackground>
          </View>
          <View>
            <Text style={[s.mb16, isDesktop ? s.f20 : s.f16, s.p16]}>Ozobot Pacing Guides help you plan CS and content-integrated lessons by grade level.</Text>
          </View>
        </View>
        <View style={[]}>
          {CONTENT.guides.map(((guide, i) => <PacingGuideSection key={i} isDesktop={isDesktop} browserWidowWidth={browserWidowWidth} {...guide} />))}
        </View>
      </Content>
    </Layout>
  )
}
