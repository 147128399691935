import React, { useState } from 'react'
import { Text, View, ImageBackground, TouchableOpacity, StyleProp, TextStyle, ViewStyle } from 'react-native'
import { getGradesRange, OZOBOT_AUTHOR_ID, lessonAppFromValue } from 'lib/utils.js'
import { Image } from 'components/ReactNative'
import { Link } from 'components/Router'
import { LessonType } from 'components/LessonTypes'
import SaveLesson from './SaveLesson'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import Slider, { Settings as SliderSettings  } from 'react-slick';
import { Column, Row } from 'react-native-web-ui-components'
import { openRoute } from './../utils'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slick-style.css';


const lessonIsNew = (lesson: LessonType) => {
  const dateStr = lesson.createdAt
  if(dateStr) {
    const ageMS = Date.now() - Date.parse(dateStr)
    const ageDays = ageMS / (86400000)
    return ageDays < 14
  }

  return false
}

const lessonHasCodingStyle = (lesson: LessonType, codingStyle: string) => 
  lesson.codingStyles?.length > 0 && lesson.codingStyles.includes(codingStyle)

const lessonHasBot = (lesson: LessonType, bot: string, exclusive: Boolean = false) => 
  lesson.bots?.length > 0 && lesson.bots.includes(bot) && !(exclusive && lesson.bots?.length > 1)

const lessonHasProduct = (lesson: LessonType, product: string, exclusive: Boolean = false) => 
  lesson.products?.length > 0 && lesson.products.includes(product) && !(exclusive && lesson.products?.length > 1)

const tileIcons = [
  {
    text: 'Lettuce Grow',
    icon: require('images/OzoGrows-Icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasProduct(lesson, 'Lettuce Grow'),
  },
  {
    text: 'Ari',
    icon: require('images/dashboard-ari-icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasBot(lesson, 'Ari', true),
  },
  {
    text: 'ORA',
    icon: require('images/dashboard-ora-icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasBot(lesson, 'ORA'),
  },
  {
    text: 'STEAM Kits',
    icon: require('images/dashboard-streamkits-icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasProduct(lesson, 'STEAM Kits'),
  },
  {
    text: 'Python',
    icon: require('images/dashboard-python-icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasCodingStyle(lesson, 'Python'),
  },   
  {
    text: 'Ozobot Blockly',
    icon: require('images/dashboard-blockly-icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasCodingStyle(lesson, 'OzoBlockly'),
  },
  {
    text: 'Color Codes',
    icon: require('images/dashboard-classroom-icon.png'),
    predicate: (lesson: LessonType): boolean => lessonHasCodingStyle(lesson, 'Color Codes'),
  },
]

export const iconInfoForLesson = (lesson: LessonType) => {
  const info = tileIcons.find(ti => ti.predicate(lesson))
  return info
    ? info
    : undefined
}

const otherAppLabel = 'Other'
const appTitleForID = (appID: string) => lessonAppFromValue(appID)?.label || otherAppLabel

const SectionFrame = (props: {
  isDesktop: boolean,
  children: React.ReactNode
}) => (
  <View
    style={[
      s.mt20,
      props.isDesktop
        ? [ s.mx50 ]
        : [ s.ml20 ],
    ]}
  >
    {props.children}
  </View>  
)

interface LessonIconProps {
  lesson: LessonType,
  isDesktop: boolean,
  categoryInfo?: object | undefined
}

const LessonIcon = ({ lesson, isDesktop }: LessonIconProps) => {
  const iconInfo = iconInfoForLesson(lesson)
  return (
    <View style={[s.flexColumn, s.alignCenter, s.justifyStart]}>
      <ImageBackground
        source={iconInfo ? iconInfo.icon : { uri: lesson.coverImageUrl }}
        style={isDesktop ? { width: 56, height: 56 } : { width: 42, height: 42 }}
        imageStyle={{ borderRadius: 4 }}
      >
        {lesson.authorId === OZOBOT_AUTHOR_ID && false &&
          <View style={[s.positionAbsolute, { top: 0, left: 0 }]}>
            <Image
              source={require('images/lessons-overlay-ozobot.png')}
              style={{ height: 30, width: 30 }}
              resizeMode="contain"
            />
          </View>
        }

        {!!lesson.hasVideo &&
          <View style={[s.positionAbsolute, { bottom: 0, right: 0 }]}>
            <Image
              source={require('images/lessons-overlay-video.png')}
              style={isDesktop ? { height: 22, width: 24 } : { height: 12, width: 20 }}
              resizeMode="contain"
            />
          </View>
        }

        {lessonIsNew(lesson) &&
          <View style={[s.positionAbsolute, { bottom: 30, left: isDesktop ? 70 : 50 }]}>
            <Image
              source={require('images/new-lesson-flag.png')}
              style={isDesktop ? { height: 20, width: 40 } : { height: 14, width: 29 }}
              resizeMode="contain"
            />
          </View>
        }
      </ImageBackground>
      <View style={[s.w100, s.h20, s.alignCenter, s.mt4]}>
        {!!iconInfo && <Text style={[s.f12, s.textGray]}>{iconInfo.text}</Text>}
      </View>
    </View>
  )
}

const getBaseTileWidth = (isDesktop: boolean) => isDesktop ? 420 : 310

export const getLessonAppIcon = (lesson: LessonType) => lesson.apps.map(lessonAppFromValue).filter(a => !!a)[0]?.icon
export const LessonTitleTextWithEmoji = (props: {
  lesson: LessonType, 
  iconSize: number,
  style: StyleProp<TextStyle>,
  viewStyle?: StyleProp<ViewStyle>,
  numberOfLines?: number,
  ellipsizeMode?: 'tail'
}) => {
  return (
    <View style={[s.flexRow, s.alignCenter, props.viewStyle]}>
      <Text style={props.style} numberOfLines={props.numberOfLines} ellipsizeMode={props.ellipsizeMode}>
        {props.lesson.title}
      </Text>
      {!!props.lesson.emojiUrl && <Image 
        source={{ uri: props.lesson.emojiUrl }} 
        style={{ height: props.iconSize, width: props.iconSize, marginLeft: Math.floor(props.iconSize / 3) }}
      />}
    </View>
  )
}

interface ResultProps {
  isSaved: boolean,
  lesson: LessonType,
  isDesktop: boolean,
  width?: number | undefined
  disabled?: boolean
}

export const Result: React.FC<ResultProps> = ({ isSaved, lesson, isDesktop, width, disabled }: ResultProps) => {
  return (
    <TouchableOpacity
      style={[
        isDesktop
          ? [
            { width, height: 320 },
            s.px32, s.py16,
            s.br16,
          ]
          : [
            { width, height: 240 },
            s.px16, s.py16,
            s.br8,
          ],
        s.bgGrayMoreLighter,
        s.bShadow,
        s.flexColumn,
        s.flex1,
        s.justifyBetween,
        s.alignStart,
      ]}
      onPress={() => { openRoute('/lessons/' + lesson.id) }}
      disabled={disabled}
    >
      <View style={[s.flexRow, s.w100p, s.justifyBetween, s.alignStart, s.mb8]}>
        <LessonIcon
          lesson={lesson}
          isDesktop={isDesktop}
        />
        <View style={[{ width: isDesktop ? 70 : 50 }]}>
          <SaveLesson
            lesson={lesson}
            isSaved={isSaved}
            isDesktop={isDesktop}
            hasPreview
          />
        </View>
      </View>
      <View style={[
        s.flexColumnReverse,
        s.justifyStart,
        s.alignStart,
      ]}>
        <View style={[s.flexRow, s.flex1, isDesktop ? s.mt20 : s.mt8]}>
          <Text style={[isDesktop ? s.f14 : s.f12, s.textPurple, s.w60]} numberOfLines={1} ellipsizeMode='tail'>
            {getGradesRange(lesson.grades)}
          </Text>
          <Text style={[isDesktop ? s.f14 : s.f12, s.textPurple, s.ml8, { width: isDesktop ? 250 : 180 }]} numberOfLines={1} ellipsizeMode='tail'>
            {lesson.subjects.join(', ')}
          </Text>
        </View>
        <View style={[s.bTop1, s.bGray, s.w100p, s.mt4, isDesktop ? s.h80 : s.h60]}>
          <Text
            style={[isDesktop ? s.f16 : s.f14, s.textGray, s.mt4, s.justifyStart]}
            numberOfLines={3}
            ellipsizeMode='tail'
          >
            {lesson.summary}
          </Text>
        </View>
        <LessonTitleTextWithEmoji 
          lesson={lesson}
          iconSize={24}
          style={[isDesktop ? s.f20 : s.f18, s.textBold]}
        />
        <Text style={[isDesktop ? s.f16 : s.f14, s.textBold, s.textGrayDarker]}>
          {lesson.authorId === OZOBOT_AUTHOR_ID ? 'Ozobot' : 'Community Lesson'}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const SectionTitle = (props: { title: string }) => (<Text style={[s.f24, s.textBold, s.pb16]}>{props.title}</Text>)

const SlidingResultsSection = (props: {
  lessons: LessonType[],
  saved: string[],
  title: string,
  total: number,
  key: React.Key,
  onViewMore?: Function,
}) => {

  const { isDesktop, browserWidowWidth } = useDetectDeviceType()
  const [ disableResultClicks, setDisableResultClicks ] = useState(false)
  const baseTileWidth = getBaseTileWidth(isDesktop)
  const numTilesToShow = Math.floor(browserWidowWidth / baseTileWidth) + (isDesktop ? 0 : 0.1)

  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToScroll: Math.max(1, Math.floor(numTilesToShow / 2)),
    slidesToShow: numTilesToShow,
    touchThreshold: 16,
    arrows: true,
    beforeChange: () => setDisableResultClicks(true),
    afterChange: () => setDisableResultClicks(false),
    prevArrow: isDesktop
      ? (<div><Image style={{ width: 14, height: 24 }} source={require('images/slick-slider-arrow-prev.svg')}/></div>)
      : undefined,
    nextArrow: isDesktop
      ? (<div><Image style={{ width: 14, height: 24 }} source={require('images/slick-slider-arrow-next.svg')}/></div>)
      : undefined,
  } as SliderSettings;

  return (
    <SectionFrame isDesktop={isDesktop} key={props.key}>
      <TouchableOpacity activeOpacity={1} onPress={ () => props?.onViewMore() } style={[s.w500]}>
        <SectionTitle title={props.title} />
      </TouchableOpacity>
      <Slider {...settings}>
        { props.lessons.map(lesson => (
          <View key={lesson.id} style={[s.h100p]}>
            <Result
              lesson={lesson}
              isSaved={props.saved.includes(lesson.id)}
              isDesktop={isDesktop}
              disabled={disableResultClicks}
            />
          </View>
        )) }
      </Slider>
      {isDesktop &&
        <TouchableOpacity activeOpacity={1} onPress={ () => props?.onViewMore() }
          style={[s.positionAbsolute, s.right0, s.w80]}>
          <View><Text>View More</Text></View>
        </TouchableOpacity>
      }
    </SectionFrame>
  )
}

interface CategoryEntry {
  entries: LessonType[],
  totalResults: number,
  saved: string[],
  name: string,
}

const NoResult: React.FC = () => {
  return (
    <View style={[s.py30, s.mx32]}>
      <Text style={[s.f20]}>
        Hmm, we don’t have anything matching the keyword(s) you searched. Try a
        different term or{' '}
        <Link to="/lessons/new">
          <Text style={[s.textTeal, s.textUnderline]}>
            create your own lesson
          </Text>
        </Link>
        !
      </Text>
    </View>
  )
}

export interface SearchResultsProps {
  lessons: LessonType[],
  total: number,
  saved: string[],
  noResult?: JSX.Element | undefined
}

export const SearchResults = ({
  lessons,
  total,
  saved,
  noResult,
}: SearchResultsProps) => {

  const { isDesktop, browserWidowWidth } = useDetectDeviceType()

  // FIXME: There MUST be an easier way of laying out these tiles in an equal grid in a responsive way than this!
  const thresholds = isDesktop
    ? [
      {
        width: 1000,
        val: 12,
      },
      {
        width: 1500,
        val: 6,
      },
      {
        width: 1800,
        val: 4,
      },
      {
        width: 2900,
        val: 3,
      },
      {
        width: 5200,
        val: 2,
      },
    ]
    : [
      {
        width: 700,
        val: 12,
      },
      {
        width: 1500,
        val: 6,
      },
    ]

  let sizeVal = thresholds[thresholds.length - 1].val
  for(let i = 0; i < thresholds.length; ++i) {
    if(browserWidowWidth < thresholds[i].width) {
      sizeVal = thresholds[i].val
      break
    }
  }

  return total > 0
    ? (
      <Row style={[ isDesktop ? s.px32 : s.px8, s.alignStretch]}>
        { lessons.map(lesson => (
          <Column
            xs={sizeVal}
            sm={sizeVal}
            md={sizeVal}
            lg={sizeVal}
            xl={sizeVal}
            key={lesson.id}
            style={[s.p10]}
          >
            <Result
              lesson={lesson}
              isSaved={saved.includes(lesson.id)}
              isDesktop={isDesktop}
            />
          </Column>
        )) }
      </Row>
    )
    : noResult
      ? (noResult)
      : (<NoResult/>)
}

export const SearchResultsByCategory = (props: { 
  categorizedResults: CategoryEntry[], 
  saved: string[], 
  onViewMore?: Function, 
  isDesktop: boolean 
}) => (<View>
  {props.categorizedResults.filter(cr => cr.entries.length > 0).map((category: CategoryEntry, index: number) => (
    <SlidingResultsSection
      key={index}
      lessons={category.entries}
      total={category.totalResults}
      saved={props.saved}
      title={category.name}
      onViewMore={() => props.onViewMore(category.name)}
    />
  ))}
</View>)

export const SearchResultsByApp = (props: {
  lessons: LessonType[],
  saved: string[],
  onViewMore?: Function,
  noResult?: JSX.Element | undefined,
  isDesktop: boolean
}) => {
  if(props.lessons.length > 0) {
    const buckets = {}
    let sortedBuckets = []
    {
      // bucket lessons with apps
      const bucketedLessonsSoFar = {}
      for(const lesson of props.lessons) {
        for(const app of lesson.apps.filter(a => !!a)) {
          const label = appTitleForID(app)
          if(!buckets[label]) {
            buckets[label] = [lesson]
          } else {
            buckets[label].push(lesson)
          }
          bucketedLessonsSoFar[lesson.id] = true
        }
      }

      sortedBuckets = Object.keys(buckets)
      sortedBuckets.sort()

      // Create an intro section
      const introLabel = 'Introduction to Ari'
      for(const lesson of props.lessons.filter(l => l.isIntro)) {
        if(!buckets[introLabel]) {
          buckets[introLabel] = [lesson]
          sortedBuckets.unshift(introLabel)
        } else {
          buckets[introLabel].push(lesson)
        }
        bucketedLessonsSoFar[lesson.id] = true
      }

      // create an 'Other' app, for lessons that have no app (this shouldn't happen...)
      for(const lesson of props.lessons.filter(l => !bucketedLessonsSoFar[l.id])) {
        if(!buckets[otherAppLabel]) {
          buckets[otherAppLabel] = [lesson]
          sortedBuckets.push(otherAppLabel)
        } else {
          buckets[otherAppLabel].push(lesson)
        }
      }
    }
    return (<View>
      {sortedBuckets.map((bucket: string, index: number) => (
        <View key={index}>
          <SectionFrame isDesktop={props.isDesktop}>
            <SectionTitle title={bucket}/>
          </SectionFrame>
          <SearchResults 
            lessons={buckets[bucket]}
            total={buckets[bucket].length}
            saved={props.saved}
          />
        </View>
      ))}
      <TouchableOpacity activeOpacity={1} onPress={ () => props?.onViewMore() } style={[s.mr64, s.mt45, s.alignEnd]}>
        <Text style={[s.f20, s.textTeal, s.textUnderline]}>See All Ari Compatible Lessons {'>'}</Text>
      </TouchableOpacity>
    </View>)
  } else {
    return props.noResult
      ? (props.noResult)
      : (<NoResult/>)
  }
}

export default SearchResults
