import React from 'react'
import { Text, View } from 'react-native'
import { CheckBoxItem, ToggleSwitch } from 'components/Form'
import s from 'styles'

const columns3Styles = [s.mb8, s.col6, { maxWidth: '30%' }]
const columns2Styles = [s.mb8, s.col6, { maxWidth: '50%' }]
const columnsDefaultStyles = [s.mb8, s.col4]
const columnsSpacingStyles = [
  s.px8,
  s.textCenter,
  { whiteSpace: 'normal' },
]

const styleForNumColumns = (numColumns: number) => 
  numColumns === 2
    ? columns2Styles
    : numColumns === 3
      ? columns3Styles
      : columnsDefaultStyles

const Frame = (props: {
  title: string,
  text?: string,
  horizontal?: boolean,
  children: React.ReactNode
}) => (
  <View style={props.horizontal ? [s.flexRow, s.alignCenter] : [s.mb20]}>
    <View style={[...props.horizontal 
      ? [ { flex: 1 }, { marginTop: 10 } ]
      : [ { maxWidth: '100%' }, s.mb16 ],
    ]}>
      <Text
        style={[
          s.f18,
          s.textBold,
          s.pb4,
          s.bGrayLighter,
        ]}
      >
        {props.title}
      </Text>
      {props.text && <Text style={[s.f14, s.textGrayDarker]}>{props.text}</Text>}
    </View>
    {props.children}
  </View>
)

export const CheckBoxSetFilter = (props: {
  title: string,
  entries: object[],
  values: string[],
  valueName: string,
  onFilterChange: Function, 
  numColumns: number,
}) => (<Frame title={props.title}>
  <View style={[s.flexRow, { flexWrap: 'wrap' }]}>
    {props.entries.map((p: { label: string, value: string }) => (
      <View key={p.value} style={styleForNumColumns(props.numColumns)}>
        <CheckBoxItem
          isChecked={props.values.includes(p.value)}
          label={p.label}
          name={props.valueName}
          onChange={props.onFilterChange}
          value={p.value}
          viewStyle={columnsSpacingStyles}
          textStyle={{ textAlign: 'left', flexShrink: 1 }}
        />
      </View>
    ))}
  </View>
</Frame>)

export const ToggleFilter = (props: {
  title: string,
  text?: string,
  value?: boolean,
  onFilterChange: Function,
}) => (<Frame title={props.title} text={props.text} horizontal={true}>
  <View style={{ marginTop: 10 }}>
    <ToggleSwitch
      value={props.value}
      onChange={props.onFilterChange}
    />
  </View>
</Frame>)

export default CheckBoxSetFilter
