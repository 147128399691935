import * as React from 'react'
import { View, Text, ImageBackground } from 'react-native'
import { Column, Row } from 'react-native-web-ui-components'
import { Content, Layout } from 'components/Layout'
import { DashboardNotifications } from 'components/DashboardNotifications'
import { ImageCard, CardProducts, DashboardMainCard, LineCard } from './components/Card'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

export default function Dashboard() {

  const { isMobile, isDesktop, isTablet } = useDetectDeviceType()

  return (
    <Layout>
      <Content>
        <DashboardNotifications />
        <DashboardMainCard
          image={require(isMobile ? 'images/dashboard-image-mobile.jpg' : 'images/dashboard-image-web.jpg')}
          headline="Explore Ari Lessons"
          text="STEAM & Core Content"
          buttonText="View Now"
          buttonLink="/lessons?query=&sort=Relevance&page=1&coding%5B%5D=Ari%20App"
        />
        <View style={[s.alignCenter]}>
          <View style={[s.mw1200, s.w100p]}>
            <Row>
              <Column style={[isDesktop ? s.col6 : s.col12, isTablet ? s.px16 : s.px8]}>
                <LineCard
                  image={require('images/new-to-classroom.png')}
                  headline="New to Classroom? Start here!"
                  text="Learn about Evo & our lesson library"
                  buttonText="Get Started"
                  buttonLink="/help/educator-training"
                />
              </Column>
              <Column style={[isDesktop ? s.col6 : s.col12, isTablet ? s.px16 : s.px8]}>
                <LineCard
                  image={require('images/returning-user.png')}
                  headline="Returning User? Welcome Back!"
                  text="Dive back into the lesson library"
                  buttonText="Let's Go"
                  buttonLink="/lessons?query=&sort=Newest&page=1"
                />
              </Column>
            </Row>
            <Row>
              <Column style={[isMobile ? s.col12 : s.col4, s.px16]}>
                <ImageCard
                  image={require('images/dashboard-image-cards/Create-A-Lesson.jpg')}
                  headline="Create A Lesson"
                  text="Upload and Share your own Ozobot lesson"
                  buttonText="Create Now"
                  buttonLink="/lessons/new"
                />
              </Column>
              <Column style={[isMobile ? s.col12 : s.col4, s.px16]}>
                <ImageCard
                  image={require('images/dashboard-image-cards/3D-CAD-Library.jpg')}
                  headline="3D CAD Library"
                  text="3D print your own Ozobot accessories"
                  buttonText="Explore Files"
                  buttonLink="https://ozobot.com/3d-cad-library"
                />
              </Column>
              <Column style={[isMobile ? s.col12 : s.col4, s.px16]}>
                <ImageCard
                  image={require('images/dashboard-image-cards/Pacing-Guides.jpg')}
                  headline="Pacing Guides"
                  text="Lesson planning made easy"
                  buttonText="Explore Guides"
                  buttonLink="/help/pacing-guides"
                />
              </Column>
            </Row>
          </View>
        </View>
      </Content>

      <View>
        <ImageBackground
          source={require('images/web-take-me-to-rounded.png')}
          resizeMode="cover"
          imageStyle={
            isMobile
              ? { width: 200, height: 53 }
              : { width: 500, height: 134 }
          }
        >
          <Text style={[
            s.textBold, s.textItalic, s.textWhite, s.textRight,
            isMobile
              ? [s.f24, { paddingTop: 18 }, { paddingRight: 40 }, s.w200]
              : [s.f35, s.p24, s.pt60, { paddingRight: 100 }, s.w500],
          ]}>
            Take me to
          </Text>
        </ImageBackground>
      </View>

      <View style={[s.textCenter, s.mw1200, s.w100p, s.mAuto, isMobile ? s.mt20 : { marginTop: 60 }, s.mb120]}>
        <Row>
          <Column xs={12} md={12}>
            <CardProducts />
          </Column>
        </Row>
      </View>

    </Layout>
  )
}
