import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import { useDispatch } from 'react-redux'
import Column from 'react-native-web-ui-components/Column'
import Row from 'react-native-web-ui-components/Row'
import { useOpenPossiblyBlockedLessonRoute } from 'pages/lessons/components/BlockedLessonView'

import s from 'styles'

export const ozobotProducts = [
  {
    text: 'Intro to Blockly Lessons',
    icon: require('images/dashboard-blockly-icon.png'),
    link: '/lessons?query=&sort=Newest&page=1&categories%5B%5D=Introduction%20to%20Blockly',
  },
  {
    text: 'Intro to Color Codes Lessons',
    icon: require('images/dashboard-classroom-icon.png'),
    link: '/lessons?query=&sort=Newest&page=1&categories%5B%5D=Introduction%20to%20Color%20Codes',
  },
  {
    text: 'Ari Lessons',
    icon: require('images/dashboard-ari-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&coding%5B%5D=Ari%20App',
  },
  {
    text: 'Challenge Mat Lessons',
    icon: require('images/desktop-challenge-mats-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&products%5B%5D=Challenge%20Mats',
  },
  {
    text: 'ORA Lessons',
    icon: require('images/dashboard-ora-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&bots%5B%5D=ORA',
  },
  {
    text: 'STEAM Kits Lessons',
    icon: require('images/dashboard-streamkits-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&products%5B%5D=STEAM%20Kits',
  },
  {
    text: 'Python Beta',
    icon: require('images/dashboard-python-icon.png'),
    link: 'https://beta.python.ozobot.com',
  },
  {
    text: 'Simulator',
    icon: require('images/dashboard-simulator-icon.png'),
    link: 'https://games.ozoblockly.com/shapetracer-freeform',
  },
  {
    text: 'OzoTown',
    icon: require('images/dashboard-ozoown-icon.png'),
    link: 'https://games.ozoblockly.com/ozotown-basic',
  },
  {
    text: 'ShapeTracer 1',
    icon: require('images/dashboard-shapetracer1-icon.png'),
    link: 'https://games.ozoblockly.com/shapetracer-basic',
  },
  {
    text: 'ShapeTracer 2',
    icon: require('images/dashboard-shapetracer2-icon.png'),
    link: 'https://games.ozoblockly.com/shapetracer-advanced',
  },
  {
    text: 'MetaBot Lessons',
    icon: require('images/dashboard-metabot-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&coding%5B%5D=MetaBot',
  },
]

export default function HeaderPortalMenu({ onShouldClose }: { onShouldClose: () => void }) {
  // FIXME: Trying to get a kind of onHover to make grey border on menu item.
  // const btnRef = useRef([])
  // console.log(btnRef)
  // const [focusText, setFocusText] = useState('')
  // FIXME: 8/3/21: Prefer to use Link component for lessonButton
  const dispatch = useDispatch()
  const openLessonRoute = useOpenPossiblyBlockedLessonRoute()
  return (
    <View style={[s.flexColumn, s.bgGrayLessLighter, s.p20, s.br16, s.w600, s.bShadow, {right: 50, top: 4}]}>
      <Row style={[s.flexRow, s.flexWrap, s.w100p, s.justifyEvenly, s.ml24]}>
        {ozobotProducts.map((btn, idx) =>
          <Column key={idx} xs={4}>
            <TouchableOpacity
              key={idx}
              onPress={() => { 
                openLessonRoute(btn.link, dispatch)
                onShouldClose()
              }}>
              <View style={[s.py16, s.px16, s.alignCenter, { width: 135 }]}>
                <Image source={btn.icon} style={{ height: 60, width: 60, marginBottom: 15 }}/>
                <Text style={[s.f12, s.textGrayDarkest, s.textBold, s.textCenter]}>{btn.text}</Text>
              </View>
            </TouchableOpacity>
          </Column>,
        )}
      </Row>
    </View>
  )
}

export const target = () => (
  <TouchableOpacity activeOpacity={1} style={[s.ml24]}>
    <Image
      source={require('images/header-portal-icon.svg')}
      style={{ height: 32, width: 32 }}
    />
  </TouchableOpacity>
)
