import React from 'react'
import { ImageBackground, ImageSourcePropType, Text, TouchableOpacity, View } from 'react-native'
import { Column, Row } from 'react-native-web-ui-components'
import { useDispatch } from 'react-redux'
import { Image } from 'components/ReactNative'
import { ButtonLink, ButtonLinkWhite, ButtonLinkBlack, ButtonLinkBlackMobile, ButtonLinkProps } from 'components/ButtonLink'
import { ozobotProducts } from 'components/ToolTip/tooltips/HeaderPortalMenu'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { useOpenPossiblyBlockedLessonRoute } from 'pages/lessons/components/BlockedLessonView'
import s from 'styles'
export interface CardProps extends ButtonLinkProps {
  image?: ImageSourcePropType,
  headline: string,
  text: string,
  overlayText?: string,
}

export function Card ({ image, headline, text, ...buttonProps }: CardProps) {
  return (
    <Column xs={12} sm={6} md={12} style={[s.px16, s.mb32]}>
      <View style={[
        s.px32,
        s.py16,
        s.br4,
        s.bTeal,
        s.b2,
      ]}>
        {image && <Image source={image} style={{ height: 200, width: '100%', marginBottom: 32 }} resizeMode="cover" />}
        <Text style={[s.mb4, s.f18, s.textBold]}>{headline}</Text>
        <Text style={[s.f14, s.textBlack, s.pb8]}>{text}</Text>
        <ButtonLink {...buttonProps} />
      </View>
    </Column>
  )
}

export function ImageCard ({ image, headline, text, ...buttonProps }: CardProps) {
  const { isDesktop } =  useDetectDeviceType();

  return (
    <Column xs={12} md={12} style={[ isDesktop ? s.px16 : null, s.mb32]}>
      <View style={[
        s.br10,
        s.bTeal,
        s.bgGrayMoreLighter,
        s.bShadow,
      ]}>
        {image && <Image source={image} style={{ height: 300, width: '100%', marginBottom: 20, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} resizeMode="cover" />}
        <View style={[s.px32, s.pb20]}>
          <Text style={[s.mb4, s.f20, s.textBold]}>{headline}</Text>
          <Text style={[s.f14, s.textBlack, s.textItalic, s.pb8]}>{text}</Text>
          <View style={[s.mAuto]}>
            <ButtonLinkWhite {...buttonProps} />
          </View>
        </View>
      </View>
    </Column>
  )
}

export function DashboardMainCard ({ image, headline, text, ...buttonProps }: CardProps) {

  const { isDesktop } =  useDetectDeviceType();

  return (
    <Column xs={12} style={[s.mb32]}>
      <View style={[
        s.br4,
        s.bWhite,
        s.b1,
      ]}>
        <Image
          source={image}
          style={{ width: '100%', height: isDesktop ? 600 : 400 }}
        />
        { isDesktop ? (
          <View style={[s.positionAbsolute, s.top25, s.left22p]}>
            <Image
              source={require('images/star_icon.png')}
              style={{ width: 100, height: 100 }}
            />
          </View>
        ) : (
          <View style={[s.positionAbsolute, { top: '35%', left: '10%' }]}>
            <Image
              source={require('images/star_icon.png')}
              style={{ width: 50, height: 50 }}
            />
          </View>
        )}
        <View style={[s.positionAbsolute, s.top0, s.right0]}>
          <ImageBackground
            source={require('images/dashboard-top-banner-title-rectangle.png')}
            imageStyle={{ borderRadius: 4 }}
            resizeMode="cover">
            { isDesktop ? (
              <View style={[s.p46, s.ml64, {width: 450, height: 300}]}>
                <Text style={[s.mb4, s.f35, s.textBold, s.textWhite, s.textItalic]}>{headline}</Text>
                <Text style={[s.f24, s.textWhite, s.pb8, s.textItalic]}>{text}</Text>
                <ButtonLinkBlack {...buttonProps} />
              </View>
            ) : (
              <View style={[{ width: 260, height: 150, paddingHorizontal: 40, paddingVertical: 12 }]}>
                <Text style={[s.mb4, s.f20, s.textBold, s.textWhite, s.textItalic]}>{headline}</Text>
                <Text style={[s.f16, s.textWhite, s.pb8, s.textItalic]}>{text}</Text>
                <ButtonLinkBlackMobile {...buttonProps} />
              </View>
            )}
          </ImageBackground>
        </View>
      </View>
    </Column>
  )
}

export function LineCard ({ image, headline, text, ...buttonProps }: CardProps) {
  const { isDesktop } =  useDetectDeviceType();
  const paddingStyle = isDesktop ? s.px32 : s.px16;

  return (
    <Column xs={12} md={12} style={[paddingStyle, s.mb32]}>
      <View style={[
        paddingStyle,
        s.py16,
        s.br4,
        s.bTeal,
        s.bgGrayMoreLighter,
        s.bShadow,
      ]}>
        <View style={[s.flexRow]}>
          {image && <Image source={image} style={{ height: 50, width: 50 }} />}
          <View style={[s.flex1, s.ml20, s.px32]}>
            <Text style={[s.mb4, s.f18, s.textBold]}>{headline}</Text>
            <Text style={[s.f14, s.textBlack, s.textItalic, s.pb8]}>{text}</Text>
          </View>
        </View>
        <View style={[s.mAuto]}>
          <ButtonLinkWhite {...buttonProps} />
        </View>        
      </View>
    </Column>
  )
}

export function HorizontalCard ({ image, headline, text, ...buttonProps }: CardProps) {
  return (
    <Column xs={12} style={[s.px16, s.mb32]}>
      <View style={[
        s.br4,
        s.bWhite,
        s.b1,
      ]}>
        <ImageBackground source={image} resizeMode="cover" imageStyle={{ borderRadius: 4 }}>
          <Row>
            <Column xs={12} sm={6} md={8} style={[s.justifyCenter, s.px45, s.py45]}>
              <Text style={[s.mb4, s.f22, s.textBold, s.textWhite]}>{headline}</Text>
              <Text style={[s.mb16, s.f14, s.textWhite]}>{text}</Text>
              <ButtonLink {...buttonProps} />
            </Column>
          </Row>
        </ImageBackground>
      </View>
    </Column>
  )
}

export function RightCard ({ image, ...buttonProps }: CardProps) {
  return (
    <Column xs={12} style={[s.px16, s.mb32]}>
      <View style={[
        s.br4,
        s.bGrayLighter,
        s.b1,
      ]}>
        <ImageBackground source={image} resizeMode="cover" imageStyle={{ borderRadius: 4 }}>
          <View style={[s.flexRow, s.justifyCenter, s.alignCenter, {height: '185px', paddingTop: '70px'}]}>
            <ButtonLink {...buttonProps} />
          </View>
        </ImageBackground>
      </View>
    </Column>
  )
}

export function WhiteCard ({ image, headline, ...buttonProps }: CardProps) {
  return (
    <View style={[s.px16, s.mb32]}>
      <ImageBackground source={image} resizeMode="cover">
        <Row>
          <Column xs={12} sm={6} md={8} style={[s.justifyCenter, s.px45, s.py45]}>
            <Text style={[s.mb4, s.f22, s.textBold, s.textBlack]}>{headline}</Text>
            <ButtonLink {...buttonProps} />
          </Column>
        </Row>
      </ImageBackground>
    </View>
  )
}

export interface AppProps {
  text: string,
  link: string,
  icon: ImageSourcePropType,
}

export function Product ({ text, link, icon }: AppProps) {
  const { isMobile } = useDetectDeviceType();
  const dispatch = useDispatch()
  const openLessonRoute = useOpenPossiblyBlockedLessonRoute()
  return (
    <View style={[isMobile ? s.col6 : s.col4, s.px16, s.mb24]}>
      <TouchableOpacity
        onPress={() => { openLessonRoute(link, dispatch) }}
        style={[s.br4,s.bGrayLighter, s.p16, s.alignCenter]}>
        <Image source={icon} style={isMobile ? { height: 50, width: 50, marginBottom: 8 } : { height: 100, width: 100, marginBottom: 8 }}/>
        <Text style={[ isMobile ? s.f14 : s.f18, s.textBold, s.textCenter]}>{text}</Text>
      </TouchableOpacity>
    </View>
  )
}

export function CardProducts () {
  return (
    <Column xs={12} style={[s.px16]}>
      <View style={[s.row, s.flexWrap]}>
        {ozobotProducts.map((p, i) => (<Product key={i} {...p} />))}
      </View>
    </Column>
  )
}
