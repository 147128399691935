import React from 'react'
import { Text, TouchableWithoutFeedback, View } from 'react-native'
import { withRouter } from 'react-router'
import { Link } from 'components/Router'
import { Image } from 'components/ReactNative'
import { FormContext } from 'components/Form'
import TextMarkdown from 'components/Markdown/TextMarkdown'
import AttachmentsList from './AttachmentsList'
import { getGradesRange } from 'lib/utils.js'
import s from 'styles'

const SubmissionReviewAccordion = ({ text, editUrl, location, values, ...props }) => {
  const form = React.useContext(FormContext)
  const [open, setOpen] = React.useState(false)
  const errors = form.errors.map(err => err.field)
  const hasError = values.some(val => errors.includes(val.name))

  function toggleOpen() {
    setOpen(!open)
  }

  function getValueByType(value) {
    const field = form[value.name]
    switch (value.type) {
      case 'text':
        return field
      case 'array':
        return value.name === 'grades'
          ? getGradesRange(field)
          : field.join(', ')
      case 'bullets':
        return field
          .map(item => `\u2022 ${value.beforeItem || ''}${item}`)
          .join('\n')
      case 'steps':
        return field
          .filter(item => item.instructions)
          .map((item, i) => `${i + 1}. ${item.instructions}`)
          .join('\n')
      case 'list':
        const list = field.filter(
          f =>
            (f.item && f.item.length) || (f.description && f.description.length)
        )
        return list
          .map(obj => {
            return value.order.map(v => obj[v]).join(' ')
          })
          .join('\n')
      case 'markdown':
        return field.length ? <TextMarkdown text={field} /> : null
      case 'attachments':
        return field.length ? (
          <AttachmentsList hasRemove={false} attachments={field} />
        ) : null
      case 'image':
        return field ? (
          <Image
            source={{ uri: field }}
            style={[s.h400, s.w100p]}
            resizeMode="contain"
          />
        ) : null
      default:
        return null
    }
  }

  return (
    <View
      style={[
        s.bgWhite,
        s.px32,
        s.py20,
        s.mb20,
        s.br10,
        s.b1,
        hasError ? s.bRed : s.bGrayLighter,
      ]}
    >
      <TouchableWithoutFeedback onPress={toggleOpen} style={[s.py20]}>
        <View
          style={[s.flexRow, s.justifyBetween, s.alignCenter, open && s.mb20]}
        >
          <View style={[s.flexRow, s.alignCenter]}>
            <Text
              style={[
                s.mr20,
                s.f20,
                s.textBold,
                hasError ? s.textRed : s.textBlack,
              ]}
            >
              {text}
            </Text>
            <Link to={editUrl + location.search}>
              <Text
                style={[
                  s.f16,
                  s.textBold,
                  hasError ? s.textRed : s.textGreen,
                  s.textUnderline,
                ]}
              >
                Edit
              </Text>
            </Link>
          </View>
          <Image
            source={require('images/caret-teal.svg')}
            style={[
              {
                height: 14,
                width: 24,
                transform: !open ? [{ rotate: '180deg' }] : null,
                tintColor: hasError ? '#fa7272' : null,
              },
            ]}
          />
        </View>
      </TouchableWithoutFeedback>
      {open &&
        values.filter(v => props.isAdminUser || !v.adminOnly).map((value, index) => (
          <View key={index} style={[s.mb20]}>
            <Text
              style={[
                s.f16,
                s.textBold,
                s.mb8,
                errors.includes(value.name) && s.textRed,
              ]}
            >
              {value.label}
            </Text>
            <Text style={[s.f16]}>{getValueByType(value) || '(None)'}</Text>
          </View>
        ))}
    </View>
  )
}

export default withRouter(SubmissionReviewAccordion)
