import React from 'react'
import { ImageBackground, Text, View } from 'react-native'
import { LessonType } from 'components/LessonTypes'
import LessonViewLayout from 'components/Layout/LessonViewLayout'
import VideoWithPlaylist from 'components/VideoWithPlaylist'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { lessonHasPlaylist } from 'store/lessons/util'
import { LessonTitleTextWithEmoji } from 'pages/lessons/components/SearchResults'
import { CoverImage }  from 'pages/lessons/components/ExploreMoreLessons'
import { OZOBOT_AUTHOR_ID } from 'lib/utils.js'
import s from 'styles'

const LessonRoundedTab = ({
  lesson,
  isPublic,
  nonVideoViewHack,
} : {
  lesson: LessonType,
  isPublic?: boolean,
  nonVideoViewHack?: boolean
}) => {
  const { isMobile } = useDetectDeviceType()
  const hasEmoji = !!lesson.emojiUrl
  return (
    <View style={[s.flexRow]}>
      <View style={[nonVideoViewHack ? s.w100p : isMobile ? s.w90p : s.w75p ]}>
        <ImageBackground
          source={isPublic
            ? require('images/CC-Header-Tablet-Public-01.png')
            : require('images/educator-training/CC-Header-Tablet-01.png')
          }
          resizeMode="stretch"
          imageStyle={{
            width: 'auto',
            height: isMobile ? 60 : 80,
          }}
          style={[
            s.alignStart, 
            s.justifyCenter, 
            isMobile 
              ? hasEmoji
                ? s.pt4
                : s.pt8 
              : hasEmoji
                ? s.pt8
                : s.pt16, 
            s.pl16,
          ]}
        >
          <LessonTitleTextWithEmoji 
            lesson={lesson}
            iconSize={isMobile ? 26 : 32}
            style={[isMobile ? s.f16 : s.f20, s.textBold, s.textWhite]}
            viewStyle={[s.pr50]}
            numberOfLines={1} 
            ellipsizeMode='tail'
          />
          <Text style={[ isMobile ? s.f16 : s.f20, s.textWhite]} numberOfLines={1} ellipsizeMode='tail'>
            {lesson.authorId === OZOBOT_AUTHOR_ID ? 'Ozobot' : 'Community Lesson'}
          </Text>
        </ImageBackground>
      </View>
    </View>
  )
}

const LessonViewFrame = ({
  lesson,
  mainRollContent,
  secondaryRollContent,
  imageAlignedContent,
} : {
  lesson: LessonType,
  mainRollContent: JSX.Element,
  secondaryRollContent: JSX.Element,
  imageAlignedContent?: JSX.Element,
}) => {
  const { isDesktop, isTablet } = useDetectDeviceType()
  if(lessonHasPlaylist(lesson)) {
    return (<VideoWithPlaylist
      id={lesson.videoPlaylist[0].id}
      list={lesson.videoPlaylist}
      title={lesson.title}
      noInstructions={true}
      dark={true}
      secondaryRollContent={secondaryRollContent}
    >
      <LessonRoundedTab lesson={lesson}/>
      {mainRollContent}
    </VideoWithPlaylist>)
  } else {
    const imageSource = { uri: lesson.coverImage800Url || lesson.coverImageUrl }
    const desktopImageSize = 220
    const tabletImageSize = 300
    const imageContent = isDesktop ? <View style={[s.flexRow, { height: desktopImageSize}]}>
      <View style={[s.bShadow]}>
        <CoverImage lesson={lesson} size={desktopImageSize} source={imageSource} style={{width: desktopImageSize, height: desktopImageSize}}/>
      </View>
      <View style={[s.flexColumn, s.alignStretch, s.flex1]}>
        <LessonRoundedTab
          lesson={lesson}
          nonVideoViewHack={true}
        />
        {imageAlignedContent}
      </View>
    </View>
      : isTablet ? <View style={[s.flexColumn]}>
        <View style={[s.mb16]}>
          <LessonRoundedTab
            lesson={lesson}
          />
        </View>
        <View style={[s.flexRow, { height: tabletImageSize}]}>
          <View style={[s.bShadow]}>
            <CoverImage lesson={lesson} size={tabletImageSize} source={imageSource}/>
          </View>
          {imageAlignedContent}
        </View>
      </View>
        : <View style={[s.flexColumn, s.flex1]}>
          <CoverImage lesson={lesson} source={imageSource}/>
          <LessonRoundedTab
            lesson={lesson}
          />
        </View>
    return (<LessonViewLayout
      useDesktopLayout={isDesktop}
      imageContent={imageContent}
      mainRollContent={mainRollContent}
      secondaryRollContent={secondaryRollContent}
    />)
  }
}

export default LessonViewFrame
