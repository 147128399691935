import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import qs from 'qs'
import { GRADES, SUBJECTS, CATEGORIES } from 'lib/utils.js'
import { lessonsActions, lessonSelector } from 'store/lessons'
import { ozobotAuthorSignedInSelector } from 'store/user'
import { modalShow } from 'store/modal'
import SubmissionAttachmentsUploaderGroup from './components/SubmissionAttachmentsUploaderGroup'
import SubmissionCheckboxGroup from './components/SubmissionCheckboxGroup'
import SubmissionSquareImageUploader from './components/SubmissionSquareImageUploader'
import SubmissionStandardsGroup from './components/SubmissionStandardsGroup'
import SubmissionObjectivesGroup from './components/SubmissionObjectivesGroup'
import SubmissionReviewAccordion from './components/SubmissionReviewAccordion'
import SubmissionSection from './components/SubmissionSection'
import SubmissionStep from './components/SubmissionStep'
import SubmissionTextarea from './components/SubmissionTextarea'
import SubmissionLayout from './components/SubmissionLayout'
import withSteps from './components/withSteps'
import { withFolderAttachment } from 'store/lessons/util'

const steps = [
  {
    slug: 'About Lesson',
    component: SubmissionStep,
    components: [
      {
        component: SubmissionSection,
        text: '1. Upload Your Lesson',
        secondaryText: 'All fields are required unless marked as optional. Max. size: 1GB videos, 10 MB all other files.',
        components: [
          {
            component: SubmissionAttachmentsUploaderGroup,
            text: 'Upload Lesson',
            secondaryText: 'Add a lesson PDF or video',
            field: 'attachments',
            required: true,
            large: true,
          },
          // TODO: Add text
        ],
      },
      {
        component: SubmissionSection,
        text: '2. Tell Us About Your Lesson',
        components: [
          {
            component: SubmissionTextarea,
            field: 'title',
            text: 'Lesson Title',
            maxLength: 80,
            hasSeparator: true,
          },
          {
            component: SubmissionCheckboxGroup,
            field: 'grades',
            text: 'Grade Level(s)',
            secondaryText: 'Select all that apply',
            values: GRADES,
            hasSeparator: true,
          },
          {
            component: SubmissionCheckboxGroup,
            text: 'Subjects/Topics',
            secondaryText:
              'Choose the most relevant subject(s). Select up to 3.',
            field: 'subjects',
            values: SUBJECTS,
          },
          {
            component: SubmissionObjectivesGroup,
            text: 'Other Subjects',
            secondaryText: 'Optional. Eg. Astronomy',
            field: 'subjectSuggestions',
            hasSeparator: true,
          },
          {
            text: 'Main Listing Categories',
            component: SubmissionCheckboxGroup,
            field: 'categories',
            values: CATEGORIES,
            hasSeparator: true,
            adminOnly: true,
          },
          {
            component: SubmissionSection,
            zIndex: '1',
            components: [
              {
                component: SubmissionStandardsGroup,
                text: 'Academic Standards',
                secondaryText: "At least one standard required. Choose a category from the dropdown on the left. In the blank on the right, begin typing the number of the standard. An auto-filled list should appear. Click on the standard to add to the lesson. If using the ‘Other’ category, type the entire standard in the blank.",
                field: 'academicStandards',
              },
            ],
          },
          {
            text: 'Add a Cover Image',
            component: SubmissionSection,
            components: [
              {
                component: SubmissionSquareImageUploader,
                field: 'coverImageUrl',
                required: true,
                errorMessage: 'Use an image file, like JPEG or PNG',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    text: 'Review',
    secondaryText: `So close! Does everything look correct?`,
    slug: 'Review',
    component: SubmissionStep,
    components: [
      {
        component: SubmissionReviewAccordion,
        text: '1. About Lesson',
        editUrl: '/lessons/uploader/1',
        values: [
          { label: 'Attachments', name: 'attachments', type: 'attachments' },
          { label: 'Title', name: 'title', type: 'text' },
          { label: 'Grade Level(s)', name: 'grades', type: 'array' },
          { label: 'Subjects/Topics', name: 'subjects', type: 'array' },
          {
            label: 'Subjects Suggestion',
            name: 'subjectSuggestions',
            type: 'array',
          },
          {
            label: 'Academic Standards',
            name: 'academicStandards',
            type: 'bullets',
          },
          { label: 'Cover Image', name: 'coverImageUrl', type: 'image' },
        ],
      },
    ],
  },
]

const formInitialState = {
  attachments: [],
  coverImageUrl: '',
  grades: [],
  academicStandards: [],
  otherAcademicStandards: [],
  subjects: [],
  subjectSuggestions: [],
  title: '',
  categories: [],
}

/**
 * URL: /lessons/uploader
 * qs params: id
 */
class Uploader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: isEmpty(props.lesson)
        ? withFolderAttachment(formInitialState)
        : props.lesson,
      errors: [],
    }
  }

  componentDidUpdate(prevProps) {
    // Update form state after lessonsViewRequest succeeds
    if (prevProps.lesson.id !== this.props.lesson.id) {
      this.updateForm(this.props.lesson)
    }
  }

  isValid() {
    // predicates
    const pdfP = field => !field.some(f => f.mime === 'application/pdf')
    const truthyP = field => !field
    const arrayP = field => field.length === 0

    const errors = [
      { label: 'Attachment', field: 'attachments', p: pdfP },
      { label: 'Title', field: 'title', p: truthyP },
      { label: 'Grades', field: 'grades', p: arrayP },
      { label: 'Subjects', field: 'subjects', p: arrayP },
      { label: 'Academic Standards', field: 'academicStandards', p: arrayP },
      { label: 'Cover Image', field: 'coverImageUrl', p: truthyP },
    ]
      .filter(e => e.p(this.state.form[e.field]))
      .map(e => ({ ...e, p: undefined }))

    this.setState({
      errors,
      errorMessage: errors.map(({ label }) => label).join(', '),
    })

    return !errors.length
  }

  onSave = () => {
    this.props.save(this.state.form)
  }

  onSubmit = () => {
    if (this.isValid()) {
      this.props.submit(this.state.form)
    }
  }

  updateForm = obj => {
    const updatedFields = Object.keys(obj)
    this.setState(prevState => {
      const newErrors = prevState.errors.filter(
        error => !updatedFields.includes(error.field)
      )

      return {
        form: Object.assign({}, prevState.form, obj),
        errors: newErrors,
        errorMessage: newErrors.map(({ label }) => label).join(', '),
      }
    })
  }

  save = () => {
    this.props.save(this.state.form)
  }

  submit = () => {
    if (this.isValid()) {
      this.props.submit(this.state.form)
    }
  }

  render() {
    // TODO: Move to componentShouldUpdate? Data is still fetching
    if (this.props.id && !this.state.form.id) {
      return null
    }

    return (<SubmissionLayout
      step={this.props.step}
      steps={steps}
      isAdminUser={this.props.isAdminUser}
      text={'Lesson Creator'}
      formProviderValue={{
        ...this.state.form,
        errors: this.state.errors,
        updateForm: this.updateForm,
      }}
      scrollView={this.props.scrollView}
      isFetching={this.props.isFetching}
      errorMessage={this.state.errorMessage}
      goTo={this.props.goTo}
      onSave={this.onSave}
      onSubmit={this.onSubmit}
    />)
  }
}

Uploader.steps = steps

const mapStateToProps = (state, ownProps) => {
  const { id } = qs.parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  })

  return {
    isFetching: state.lessons.isFetching,
    lastUpdatedId: state.lessons.lastUpdatedId,
    lesson: lessonSelector(state, id),
    isAdminUser: ozobotAuthorSignedInSelector(state),
  }
}

export default connect(mapStateToProps, {
  modalShow,
  lessonsViewRequest: lessonsActions.viewRequest,
  lessonsUpdateRequest: lessonsActions.updateRequest,
  lessonsCreateRequest: lessonsActions.createRequest,
})(withSteps(Uploader, { uri: 'uploader', type: 'upload' }))
