
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View, Text, Image, ImageStyle, ImageSourcePropType } from 'react-native'
import Button from 'components/Form/Button2'
import s from 'styles'
import { LessonType } from 'components/LessonTypes'
import { LessonTitleTextWithEmoji } from 'pages/lessons/components/SearchResults'
import { lessonAppFromValue, OZOBOT_AUTHOR_ID } from 'lib/utils.js'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { lessonsActions, lessonsResultsSelector, lessonsIsFetchingSelector } from 'store/lessons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { openRoute } from './../utils'
import { openLink } from 'pages/util'

const AuthorSubjectsAndSaves = ({ lesson, isDesktop, isMobile } : { lesson: LessonType, isDesktop: boolean, isMobile: boolean }) => {
  const DotText = (props: { text: string, maxWidth?: number, isMobile: boolean }) => (
    <View style={[s.flexRow, s.flexShrink1, s.mr8, props.maxWidth && { maxWidth: props.maxWidth }]}>
      <Text style={[ isDesktop ? s.f12 : isMobile ? s.f14 : s.f16, s.textLight, s.textGrayDarker ]} numberOfLines={1} ellipsizeMode='tail'>{props.text}</Text>
    </View>
  )
  return (
    <View style={[ s.flexRow, s.flex1, isMobile ? s.mt4 : s.mt8 ]}>
      <DotText text={lesson.authorId === OZOBOT_AUTHOR_ID ? 'Ozobot' : 'Community Lesson'} isMobile={isMobile}/>
      <DotText text={'\u2022'} isMobile={isMobile}/>
      <DotText text={'' + lesson.userSaveCount + ' saved'} isMobile={isMobile}/>
    </View>
  )
}

export const CoverImage = (props: { lesson: LessonType, source?: ImageSourcePropType, size?: number, style?: ImageStyle }) => {
  const imageSrc = props.source || { uri: props.lesson.coverImage800Url || props.lesson.coverImageUrl }
  const extraStyle = props.style || {}
  const appBadge = props.lesson.apps.map(lessonAppFromValue).filter(a => !!a)[0]?.icon
  const badgeSize = props.size 
    ? props.size / 3 
    : 120

  return (
    <View>
      <Image
        source={imageSrc}
        style={{
          resizeMode: 'cover',
          width: props.size,
          height: props.size,
          aspectRatio: 1,
          ...extraStyle,
        }}
      />
      {appBadge && <View style={[s.positionAbsolute, s.top0, s.left0]}>
        <Image 
          source={appBadge}
          style={{
            width: badgeSize,
            height: badgeSize,
          }}
        />
      </View>}
    </View>
  )
}

const LessonEntry = ({ lesson, isDesktop, isMobile }: { lesson: LessonType, isDesktop: boolean, isMobile: boolean }) => {
  const openLesson = () => openRoute('/lessons/' + lesson.id)
  const imageSrc = { uri: lesson.coverImageUrl }
  const shadowProps = {
    shadowColor: 'rgba(0,0,0,0.20)',
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
    shadowOpacity: 1.0,
  }
  const imageSize = isMobile ? 200 : 144
  return isMobile
    ? (
      <TouchableOpacity
        style={[s.flexColumn, s.alignStretch, s.flex1, s.mt24]}
        onPress={openLesson}
      >
        <CoverImage
          lesson={lesson}
          size={imageSize}
          style={{
            borderRadius: isMobile ? 8 : 5,
            ...shadowProps,
          }}
        />
        <LessonTitleTextWithEmoji 
          lesson={lesson} 
          iconSize={24} 
          style={[s.flexShrink1, s.f14]}
          viewStyle={[s.mt8]}
          numberOfLines={1} 
          ellipsizeMode='tail'
        />
        <AuthorSubjectsAndSaves
          lesson={lesson}
          isDesktop={isDesktop}
          isMobile={isMobile}
        />
      </TouchableOpacity>
    )
    : (
      <TouchableOpacity
        style={[s.flexRow, s.flex1, s.my8]}
        onPress={openLesson}
      >
        <CoverImage
          lesson={lesson}
          source={imageSrc}
          size={150}
          style={{
            borderRadius: 8,
            ...shadowProps,
          }}
        />
        <View style={[s.flexColumn, s.flex1, s.alignStretch, s.ml16]}>
          <LessonTitleTextWithEmoji 
            lesson={lesson} 
            iconSize={24} 
            style={[s.flexShrink1, s.f16]} 
          />
          <AuthorSubjectsAndSaves
            lesson={lesson}
            isDesktop={isDesktop}
            isMobile={isMobile}
          />
        </View>
      </TouchableOpacity>
    )
}

const ExploreMoreLessons = (props: { searchKey?: string }) => {

  const lessons = useSelector(lessonsResultsSelector)
  const isFetching = useSelector(lessonsIsFetchingSelector)
  const key = 'limit=20&' + (props.searchKey || 'sort=newest&categories=Featured%20Lessons')
  const dispatch = useDispatch()

  useEffect(() => {
    if(lessons.length === 0 && !isFetching) {
      dispatch(lessonsActions.searchRequest({
        query:'',
        sort:'Newest',
        page:'1',
        limit:20,
        grade:[],
        subject:[],
        bots:[],
        coding:[],
        author:[],
        products:[],
        duration:[],
        categories:[],
        key,
      }))
    }
  }, [lessons, isFetching])

  const { isDesktop, isMobile } = useDetectDeviceType()

  return (
    <View style={[s.flexColumn, s.flex1, s.alignStretch]}>
      <Text style={[s.f20, s.textBold]}>Explore more lessons</Text>
      {lessons.slice(0, isMobile ? 5 : 9).map((l, k) => (
        <LessonEntry lesson={l} key={k} isDesktop={isDesktop} isMobile={isMobile}/>
      ))}
      <View style={[s.alignSelfCenter, s.mt20]}>
        <Button
          text={'Show more...'}
          role={'secondary'}
          onPress={() => openLink('/lessons?' + key, dispatch)}
        />
      </View>
    </View>
  )
}

export default ExploreMoreLessons
