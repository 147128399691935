import React, { useEffect, useState, ReactNode } from 'react'
import { View, Text, Dimensions, Image, ViewStyle, ImageSourcePropType, StyleProp } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'components/Form/Button2'
import { lessonByIdSelector, lessonsActions, lessonsSavedSelector, lessonBlockedStatusSelector, lessonsIsFetchingSelector } from 'store/lessons'
import { LessonType } from 'components/LessonTypes'
import { ModalFrame } from 'components/modal'
import { modalShow } from 'store/modal'
import { Content, Layout } from 'components/Layout'
import { ModalShareLesson, getSavedImage } from './components/SaveLesson'
import { getGradesRange } from 'lib/utils.js'
import s from 'styles'
import MissingPage from 'pages/404'
import WebView from 'components/WebView'
import { openURL, portalLessons, makePDFViewURL } from 'lib/utils.js'
import { openRoute } from './utils'
import LessonViewFrame from './components/LessonViewFrame'
import { iconInfoForLesson } from './components/SearchResults'
import ExploreMoreLessons from './components/ExploreMoreLessons'
import LoadingLessons from './components/LoadingLessons'
import { DisplayChecklist } from 'components/TabbedLessonInfo'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { TouchableOpacity } from 'react-native-gesture-handler'
import RatingSelection from 'components/RatingSelection'
import { lessonPublicRatingSelector } from 'store/lessons'
import { lessonHasPlaylist, shouldShareLesson } from 'store/lessons/util'
import BlockedLessonView from './components/BlockedLessonView'
import { userCanViewORALessonsSelector } from 'store/user'

export interface RuleViewType {
  children?: React.ReactNode
  style?: ViewStyle[],
}
export function RuleView({ children, style }: RuleViewType) {

  const test = React.Children.toArray(children).filter((child) => React.isValidElement(child)) as React.ReactElement[]
  if (test.length > 0)
    return (
      <View
        style={[
          s.bBottom1, s.bGrayLighter,
          ...style,
        ]}
      >
        {children}
      </View>
    )
  else return null
}

// Handles cases when standards are returned as an array of objects
const getFormattedAcademicStandards = (standards, other) => {
  standards = standards || []
  other = other || []
  return (
    standards.map(s => (s.label ? s.label : s)).join(', ') +
    (standards?.length > 0 && other?.length > 0 ? '\n' : '') +
    other.map(s => (s.label ? s.label : s)).join(', ')
  )
}

const PrintLink = ({ text, url }: { text: string; url?: string }) => (
  <View style={[s.flexRow, s.alignCenter, s.ml32]}>
    <Image
      source={require('images/print-icon.svg')}
      style={{
        width: 17, height: 17, marginRight: 8, resizeMode: 'contain',
      }}
    />
    {url ? (<Text onPress={() => openURL(url, true)} numberOfLines={1} style={[s.f16, s.textBold, s.textTeal, s.textUnderline]}>{text}</Text>) :
      (<Text numberOfLines={1} style={[s.f16]}>{text}</Text>)}
  </View>
)

const DownloadLink = ({ text, url }: { text: string; url?: string }) => {
  const { isDesktop } = useDetectDeviceType()
  return (
    <View style={[s.flexRow, s.alignCenter, isDesktop ? s.ml32 : null, s.b1, s.br25, s.bGreenDark, s.p8]}>
      <Image
        source={require('images/download-icon-dark-green.svg')}
        style={{
          width: 17, height: 17, marginRight: 8, resizeMode: 'contain',
        }}
      />
      {url ? (<Text onPress={() => openURL(url, true)} numberOfLines={1} style={[s.f16, s.textBold, s.textGreenDark, s.textUnderline]}>{text}</Text>) :
        (<Text numberOfLines={1} style={[s.f16, s.textGreenDark]}>{text}</Text>)}
    </View>
  )
}

function AssetLink({ text, url, hide }: { text: string; url?: string; hide?: Array<string> }) {
  if (hide?.includes(url)) return null
  return (
    <Text
      style={[s.f18, s.textBold, s.textGreenDark, s.mb8]}
      numberOfLines={1}
      onPress={() => openURL(url, true)}
    >
      {text}
    </Text>
  )
}

interface LessonModalType {
  lesson: LessonType
  uri?: string
}

const LessonModal = ({ lesson, uri }: LessonModalType) => {
  const [showChecklist, setShowChecklist] = useState(false)
  const dim = Dimensions.get('window')
  return (
    <ModalFrame title="Lesson Details" size="large" noMargins={true} >
      <View style={[s.flexRow, s.mx32, s.justifyBetween, s.flexRowReverse]}>
        {!showChecklist ? <PrintLink text={'print'} url={lesson.pdfUrl} /> : <View />}
        {lesson.checklist?.length > 0 && (
          <View style={[s.flexRow]} >
            <Button text={'Lesson Plan'} rounded={true} onPress={() => setShowChecklist(v => !v)} role={showChecklist ? 'justText' : undefined} />
            <Button text={'Completion Checklist'} rounded={true} onPress={() => setShowChecklist(v => !v)} role={!showChecklist ? 'justText' : undefined} />
          </View>)}
      </View>

      {!showChecklist ?
        (<View style={[{ height: dim.height - 200 }, s.w100p, s.m32]} >
          <WebView source={{ uri }} style={{ height: '100%', width: 900 - 64 }} />
        </View>) :
        (<View style={[s.m32]}><Text style={[s.f18, s.textBold, s.mb32]}>Completion Checklist – for Students</Text>
          <DisplayChecklist lesson={lesson} />
        </View>)
      }
    </ModalFrame>
  )
}

const SaveToggle = ({
  lesson,
  isSaved,
  isDesktop,
  forceSmall,
} : {
  lesson: LessonType,
  isSaved: boolean,
  isDesktop: boolean,
  forceSmall?: boolean
}) => {

  const dispatch = useDispatch()

  const onSaveOrUnsave = React.useCallback(() => {
    dispatch(lessonsActions.updateSavedRequest({ id: lesson.id }))
  }, [lesson.id])

  const large = isDesktop && !forceSmall

  const ImageWithText = ({
    image,
    width,
    height,
    text,
    onPress,
  } : {
    image: ImageSourcePropType,
    width: number,
    height: number,
    text: string,
    onPress: () => void,
  }) => (
    <TouchableOpacity
      style={[s.flexRow, s.alignCenter, large ? s.ml16 : s.ml8]}
      onPress={onPress}
    >
      <Image
        source={image}
        style={[{ width, height }]}
      />
      <Text style={[
        s.alignCenter,
        s.f18,
        s.textGreenDark,
        s.textUnderline,
        large ? s.ml8 : s.ml4,
        isSaved ? s.textBold : undefined,
      ]}>{text}</Text>
    </TouchableOpacity>
  )

  return (
    <ImageWithText
      image={getSavedImage(isSaved, true)}
      text={'Save'}
      width={large ? 23 : 18}
      height={large ? 23 : 18}
      onPress={onSaveOrUnsave}
    />
  )
}

const ViewLessonPlanButton = ({lesson, style} : {lesson: LessonType, style?: StyleProp<ViewStyle>}) => {
  const dispatch = useDispatch()
  const { isDesktop } = useDetectDeviceType()
  return (<View style={style}>
    <Button
      text='View Lesson Plan'
      role='greenBgWhiteText'
      narrow={!isDesktop}
      onPress={() => dispatch(modalShow(<LessonModal lesson={lesson} uri={makePDFViewURL(lesson.pdfUrl)} />))}
    />
  </View>)
}

const ShareWithStudentsButton = ({lesson, style} : {lesson: LessonType, style?: StyleProp<ViewStyle>}) => {
  const dispatch = useDispatch()
  const { isDesktop } = useDetectDeviceType()
  return (<View style={style}>
    <Button
      role="greenBgWhiteText"
      text='Share with Students'
      narrow={!isDesktop}
      onPress={() => dispatch(modalShow(<ModalShareLesson lesson={lesson}/>))}
    />
  </View>)
}

const FullLessonPlanButton = ({lesson, style} : {lesson: LessonType, style?: StyleProp<ViewStyle>}) => {
  return (<View style={style}>
    <DownloadLink text={'Full Lesson Plan'} url={lesson.pdfUrl} />
  </View>)
}

const ActivitySheetsButton = ({lesson, style} : {lesson: LessonType, style?: StyleProp<ViewStyle>}) => {
  return (<View style={style}>
    <DownloadLink text={'Activity Sheets'} url={lesson.studentPdfUrl} />
  </View>)
}

const  LessonSummary = ({lesson, noIndent} : { lesson: LessonType, noIndent?: boolean}) => {
  const { isDesktop, isMobile, isTablet } = useDetectDeviceType()

  const DotText = (props: { text: string, maxWidth?: number, isMobile: boolean }) => (
    <View style={[s.flexRow, s.flexShrink1, s.mr16, props.maxWidth && { maxWidth: props.maxWidth }]}>
      <Text style={[ isMobile ? s.f12 : s.f16, s.textGray ]} numberOfLines={1} ellipsizeMode='tail'>{props.text}</Text>
    </View>
  )

  const hasSpace = isDesktop || (isTablet && lessonHasPlaylist(lesson))

  return (
    <View>
      <View style={[
        s.flexRow,
        s.flex1,
        s.pt16,
        !isDesktop && !noIndent && s.px16,
        isDesktop ? s.my20 : isMobile ? s.my8 : s.my16,
      ]}>
        <DotText text={getGradesRange(lesson.grades || [])} isMobile={isMobile}/>
        <DotText text={'\u2022'} isMobile={isMobile}/>
        <DotText text={(lesson.subjects || []).join(', ')} isMobile={isMobile} maxWidth={hasSpace ? 300 : 200}/>
        <DotText text={'\u2022'} isMobile={isMobile}/>
        <DotText text={'' + lesson.userSaveCount + ' saved'} isMobile={isMobile}/>
      </View>
    </View>
  )
}

const LessonRollActionButtons = ({ lesson, isSaved, isMobile, isTablet } : { lesson: LessonType, isSaved: boolean, isMobile: boolean, isTablet: boolean }) => {
  if(!isMobile && !isTablet) {
    return null
  }
  const Wrapper = ({children, indent}: {children?: ReactNode, indent?: boolean}) => children
    ? (<View style={[s.mb24, isMobile ? s.px4 : s.pr50, isMobile ? s.col6 : s.col4, indent && s.ml24]}>{children}</View>)
    : null
  return <View style={[s.flexRow, s.flexWrap, isMobile ? s.justifyBetween : s.justifyStart, s.mt32]}>
    {lesson.pdfUrl && <Wrapper><ViewLessonPlanButton lesson={lesson}/></Wrapper>}
    {shouldShareLesson(lesson) && <Wrapper><ShareWithStudentsButton lesson={lesson}/></Wrapper>}
    {lesson.studentPdfUrl && <Wrapper><ActivitySheetsButton lesson={lesson}/></Wrapper>}
    {lesson.pdfUrl && <Wrapper><FullLessonPlanButton lesson={lesson}/></Wrapper>}
    <Wrapper><SaveToggle lesson={lesson} isSaved={isSaved} isDesktop={false} /></Wrapper>
  </View>
}

const AcademicStandardsSection = ({lesson} : {lesson: LessonType}) => {
  const { isDesktop, isTablet } = useDetectDeviceType()
  const standardsFont = isDesktop
    ? s.f18
    : isTablet
      ? s.f16
      : s.f14
  return (<View style={[s.my16]}>
    <Text style={[standardsFont, s.mb4]}>Academic Standards</Text>
    <Text style={[standardsFont, s.textLight]}>{getFormattedAcademicStandards(lesson.academicStandards, lesson.otherAcademicStandards)}</Text>
  </View>)
}

const LessonRoll = ({ lesson, isSaved } : { lesson : LessonType, isSaved : boolean }) => {
  const iconInfo = iconInfoForLesson(lesson)
  const hide = [lesson.studentPdfUrl, lesson.pdfUrl, lesson.fullVideo?.url]

  const { isDesktop, isTablet, isMobile } = useDetectDeviceType()

  const doFullLessonRoll = shouldShareLesson(lesson) || (!isDesktop && !isTablet)

  const borderedSectionPadding = [s.mt20, s.pt20]
  const borderedSectionStyles = [s.flexColumn, s.alignStretch, ...borderedSectionPadding, s.bTop1, s.bGrayLighter]
  const rollViewMarginX = !isDesktop && s.mx16

  const lessonRating: number | undefined = useSelector(lessonPublicRatingSelector(lesson.id))
  const dispatch = useDispatch()

  const onSubmit = (rating: number) => {
    dispatch(lessonsActions.rateLessonRequest({
      rating,
      lessonId: lesson.id,
      applyForUser: false,
    }))
  }

  return (
    <View style={[s.flexColumn, s.alignStretch]}>
      {doFullLessonRoll && (<LessonSummary lesson={lesson}/>)}
      <View style={[rollViewMarginX]}>
        <View style={doFullLessonRoll ? [...borderedSectionStyles] : [...borderedSectionPadding]}>
          <View style={[s.flexRow, s.alignCenter]}>
            <Image
              source={iconInfo ? iconInfo.icon : { uri: lesson.coverImageUrl }}
              style={{ width: 56, height: 56 }}
            />
            {lesson.codingStyles?.length > 0 && <View style={[s.flexRow, s.flex1]}>
              <Text style = {[s.f18, s.textLight, s.ml20, s.flexRow, s.flex1, s.flexShrink1]}>
                {'Coding '}
                <Text style = {[s.f18, s.textLight, s.flexShrink1]}>with {lesson.codingStyles.join(', ')}</Text>
              </Text>
            </View>}
            
          </View>
          {doFullLessonRoll && (
            <Text style={[isDesktop ? s.f18 : isTablet ? s.f16 : s.f14, s.textLight, s.my16]}>{lesson.summary}</Text>
          )}
          {!!lesson.academicStandards?.length && !isTablet && <AcademicStandardsSection lesson={lesson}/>}
          <LessonRollActionButtons
            lesson={lesson}
            isSaved={isSaved}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </View>
        {lesson.attachments?.length > 0 && (<View style={[...borderedSectionStyles]}>
          <View style={s.mb16}>
            <Text style={[s.f18, s.mb16]}>Resources</Text>
            {lessonHasPlaylist(lesson) && shouldShareLesson(lesson) && (
              <AssetLink text={'Video: ' + lesson.title} url={`${window.origin}/lessons/${lesson.id}/public`} />)}
            {lesson.pdfUrl && (
              <AssetLink text={'Lesson Plan'} url={lesson.pdfUrl} />)}
            {lesson.studentPdfUrl && (
              <AssetLink text={'Activity Sheets'} url={lesson.studentPdfUrl} />)}
            {lesson.attachments.map(({ url, name }) => (
              <AssetLink key={url} text={name} url={url} hide={hide} />
            ))}
          </View>
        </View>)}
        <View style={[...borderedSectionStyles]}>
          <View style={s.mb16}>
            <RatingSelection
              text="How do you feel about this lesson?"
              submittedRating={lessonRating}
              lockOnSubmit={true}
              onSubmit={onSubmit}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

const ImageAlignedContent = ({lesson} : {lesson: LessonType}) => {
  const { isDesktop, isTablet } = useDetectDeviceType()
  return (<View style={[s.flexColumn, s.alignStretch, s.flex1, s.ml32]}>
    <LessonSummary lesson={lesson} noIndent={true} />
    <Text style={[isDesktop ? s.f18 : isTablet ? s.f16 : s.f14, s.textLight, s.my16]}>{lesson.summary}</Text>
    {!!lesson.academicStandards?.length && isTablet && <AcademicStandardsSection lesson={lesson}/>}
  </View>)
}

const exploreMoreSearchKeyFromLesson = (lesson: LessonType) => {

  if(lesson.bots.includes('ORA')) {
    return 'query=&sort=Relevance&page=1&bots=ORA'
  }

  if(lesson.codingStyles.includes('Ari App')) {
    return 'query=&sort=Relevance&page=1&coding=Ari%20App'
  }

  if(lesson.products.includes('Lettuce Grow')) {
    return 'query=&sort=Relevance&page=1&products=Lettuce%20Grow'
  }

  if(lesson.products.includes('Challenge Mats')) {
    return 'query=&sort=Relevance&page=1&products=Challenge%20Mats'
  }

  if(lesson.products.includes('STEAM Kits')) {
    return 'query=&sort=Relevance&page=1&products=STEAM%20Kits'
  }

  if(lesson.products.includes('Ozobot Crawler')) {
    return 'query=&sort=Relevance&page=1&products=Ozobot%20Crawler'
  }  

  if(lesson.products.includes('Color Code Magnets')) {
    return 'query=&sort=Relevance&page=1&products=Color%20Code%20Magnets'
  }  

  if(lesson.codingStyles.includes('Python')) {
    return 'query=&sort=Relevance&page=1&coding=Python'
  }

  // Just search for featured lessons
  return 'sort=Newest&categories=Featured%20Lessons'
}

interface Props {
  saved: string[],
  lessonsUpdateSavedRequest: ({ id }) => void
  history: {
    replace: (path: string) => void
  }
  match: {
    url: string,
    params: {
      id: string,
    }
  }
  location: {
    pathname: string,
  }
}

function LessonDetail(props: Props) {
  const id = props.match.params.id
  const lesson: LessonType = useSelector(lessonByIdSelector(id))
  const isFetching = useSelector(lessonsIsFetchingSelector)
  const lessonWasBlocked = useSelector(lessonBlockedStatusSelector(id))
  const userCanViewORALessons = useSelector(userCanViewORALessonsSelector)

  const { isDesktop, isMobile } = useDetectDeviceType()
  const saved = useSelector(lessonsSavedSelector)
  const isSaved = !!saved?.find(l => l.id === lesson.id)

  const dispatch = useDispatch()
  useEffect(() => {
    const lessonId = portalLessons[props.match.params.id]
    if (lessonId) {
      dispatch(lessonsActions.viewRequest({ id: lessonId }))
      return props.history.replace(`/lessons/${lessonId}`)
    }
    else {
      dispatch(lessonsActions.viewRequest({ id: id }))
    }
  }, [id, userCanViewORALessons])

  if (!lesson) {
    // Figure out if the lesson was blocked or was just missing...
    if(lessonWasBlocked) {
      return <BlockedLessonView routeURL={`/lessons/${id}`}/>
    }

    if(isFetching) {
      return <LoadingLessons/>
    }

    return <MissingPage {...this.props} />
  }

  return (
    <Layout>
      <Content>
        <View style={[s.flexColumn, s.justifyStart, s.alignStretch, s.mb120]}>
          <View style={[s.flexRow, s.justifyStart, s.alignCenter, isDesktop ? s.h60 : s.h50, isMobile ? s.pl16 : s.pl40, s.bShadow]}>
            <TouchableOpacity onPress={() => openRoute('/lessons')}>
              <Text style={[isMobile ? s.f12 : s.f16, s.textGray]}>Lesson Library</Text>
            </TouchableOpacity>
            <Text style={[isMobile ? s.f12 : s.f16, isMobile && s.mr16]} numberOfLines={1}>
              {' > '}
              <Text style={[s.textGreenDark, s.textBold]} numberOfLines={1} ellipsizeMode='tail'>{lesson.title}</Text>
            </Text>
          </View>
          <View style={[s.flexRow, s.flex1, s.justifyCenter]}>
            <View style={[s.flexColumn, s.flex1, s.alignStretch, { maxWidth: 1600 }]}>
              {isDesktop &&
                <View style={[s.flexRow, s.justifyBetween, s.alignCenter, s.h120, s.px40]}>
                  <View style={[s.flexRow, s.alignCenter]}>
                    {lesson.pdfUrl && <ViewLessonPlanButton lesson={lesson}/>}
                    {lesson.pdfUrl && <FullLessonPlanButton lesson={lesson}/>}
                    {lesson.studentPdfUrl && <ActivitySheetsButton lesson={lesson}/>}
                    <View style={[s.ml32]}>
                      <SaveToggle
                        lesson={lesson}
                        isSaved={isSaved}
                        isDesktop={isDesktop}
                        forceSmall={false}
                      />
                    </View>
                  </View>
                  {shouldShareLesson(lesson) && <ShareWithStudentsButton lesson={lesson}/>}
                </View>
              }
              <LessonViewFrame
                lesson={lesson}
                mainRollContent={(<LessonRoll lesson={lesson} isSaved={isSaved}/>)}
                secondaryRollContent={(<ExploreMoreLessons searchKey={exploreMoreSearchKeyFromLesson(lesson)}/>)}
                imageAlignedContent={(<ImageAlignedContent lesson={lesson}/>)}
              />
            </View>
          </View>
        </View>
      </Content>
    </Layout>)
}

export default LessonDetail
