import React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { connect, useDispatch } from 'react-redux'
import { xor, omit } from 'lodash'
import qs from 'qs'
import {
  lessonsActions,
  lessonsResultsSelector,
  lessonsCategorizedResultsSelector,
  lessonsSearchTotalSelector,
} from 'store/lessons'
import { ORARegisterModal, useOpenPossiblyBlockedLessonRoute, lessonSearchLinkReferencesORALessons } from 'pages/lessons/components/BlockedLessonView'
import { userAddRecentSearchQuery, userCanViewORALessonsSelector, userRecentLessonSearchesSelector, userUnlockingStatusSelector } from 'store/user'
import { modalShow } from 'store/modal'
import { Content, Layout } from 'components/Layout'
import Pagination from 'components/Pagination'
import SearchFilters from './components/SearchFilters'
import FilterChoices from './components/FilterChoices'
import SearchResults, { SearchResultsByCategory, SearchResultsByApp } from './components/SearchResults'
import LessonFilterModal from './components/LessonsFilterModal'
import NoLessons from './components/NoLessons'
import s from 'styles'
import { withDeviceHook } from 'hooks/withDeviceHook'
import { CATEGORIES } from 'lib/utils.js'

const initialFilter = {
  grade: [],
  subject: [],
  bots: [],
  coding: [],
  author: [],
  products: [],
  duration: [],
  has_video: undefined,
  is_mini: undefined,
  categories: [],
  categorize: undefined,
}

const isAriQuery = (filter) => filter.coding?.includes('Ari App')
const pageCountForFilter = (filter) => isAriQuery(filter) ? 200 : 20

const checkboxFilters = {
  'has_video': 'Video Lessons',
  'is_mini': 'Mini Lessons'
}

const headerCategoryButtons = [
  {
    text: 'Ozobot Blockly',
    icon: require('images/dashboard-blockly-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&coding%5B%5D=OzoBlockly',
  },
  {
    text: 'Color Codes',
    icon: require('images/dashboard-classroom-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&coding%5B%5D=Color%20Codes',
  },
  {
    text: 'Python',
    icon: require('images/dashboard-python-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&coding%5B%5D=Python',
  },
  {
    text: 'STEAM Kits',
    icon: require('images/dashboard-streamkits-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&products%5B%5D=STEAM%20Kits',
  },
  {
    text: 'Lettuce Grow',
    icon: require('images/OzoGrows-Icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&products%5B%5D=Lettuce%20Grow',
  },
  {
    text: 'ORA',
    icon: require('images/dashboard-ora-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&bots%5B%5D=ORA',
  },
  {
    text: 'Ari',
    icon: require('images/dashboard-ari-icon.png'),
    link: '/lessons?query=&sort=Relevance&page=1&coding%5B%5D=Ari%20App',
  }, 
]

const SearchShortcutsMenu = ({ isMobile }) => {
  const dispatch = useDispatch()
  const openLessonLink = useOpenPossiblyBlockedLessonRoute()
  return (
    <View style={[s.flexRow, isMobile && s.flexWrap, s.justifyCenter]}>
      {headerCategoryButtons.map((btn, idx) =>
        <View key={idx} style={[{ width: 100 }, isMobile && s.mb16 ]}>
          <TouchableOpacity onPress={() => openLessonLink(btn.link, dispatch)}>
            <View style={[s.flexColumn, s.alignCenter]}>
              <Image
                source={btn.icon}
                style={{ height: 50, width: 50, marginBottom: 10 }}
              />
              <Text style={[
                isMobile ? s.f12 : s.f14,
                s.textGrayDarkest,
                s.textBold,
                s.textCenter,
              ]}>
                {btn.text}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

const DimmedOverlay = ({ top = 0, onPress = undefined }) => (
  <View
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#252932CC',
      position: 'absolute',
      top,
      left: 0,
      zIndex: 1,
    }}
  >
    <TouchableOpacity onPress={onPress} style={[s.w100p, s.h100p]}/>
  </View>
)

const NoSearchResults = () => (<NoLessons
  text={'Hmm, we don’t have anything matching the keyword(s) you searched. Try a different term or...'}
  link={'/lessons/new'}
  linkText={'Create Your Own Lesson'}
/>)

const filterStateFromLocationQuery = (search) => {
  const {
    query = '',
    sort = 'Newest',
    page = 1,
    limit = 20,
    ...newFilter
  } = qs.parse(search, {
    ignoreQueryPrefix: true,
    comma: true,
  })

  // Show categories if first creation of component, and has default URL items
  const categorize = (JSON.stringify(newFilter) === '{}' && !query) || newFilter.categorize ? true : undefined

  const filter = {
    ...initialFilter,
    ...newFilter,
    categorize,
  }

  return {
    query,
    sort,
    page,
    limit: pageCountForFilter(filter),
    filter,
  }
}

class LessonsSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...filterStateFromLocationQuery(props.location.search),
      visibleFilter: undefined,
      selected: [],
    }

    this.scrollView = React.createRef()
  }

  componentDidMount() {
    this.fetchLessons()
    this.props.lessonsSavedRequest()
  }

  stateUpdateFromQuery(search) {

    const {
      query = '',
      sort = 'Newest',
      page = 1,
      limit = 20,
      ...newFilter
    } = qs.parse(search, {
      ignoreQueryPrefix: true,
      comma: true,
    })

    // Show categories if first creation of component, and has default URL items
    const categorize = (JSON.stringify(newFilter) === '{}' && !query) || newFilter.categorize ? true : undefined

    const filter = {
      ...initialFilter,
      ...newFilter,
      categorize,
    }

    return {
      query,
      sort,
      page,
      limit: pageCountForFilter(filter),
      filter,
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.results.length !== this.props.results.length) {
      // if new results can't show on page, get last possible page
      this.updateUrl({
        page: Math.min(parseInt(this.state.page), this.getPageAmount() || 1),
      })
    }
    if (prevProps.location.search !== this.props.location.search) {
      const stateUpdate = filterStateFromLocationQuery(this.props.location.search)
      this.setState({...stateUpdate})
      this.scrollView.current.scrollTo({ x: 0, y: 0, animated: false })
      this.fetchLessons({...this.state, ...stateUpdate})
    }
  }

  getPageAmount() {
    return Math.ceil(this.props.searchTotal / this.state.limit)
  }

  isAriQuery() {
    return isAriQuery(this.state.filter)
  }

  search = query => {
    // NOTE: when searching, remove any categorization
    this.updateUrl({
      query,
      filter: {
        ...this.state.filter,
        categorize: undefined,
      },
      sort: 'Relevance'
    })
  }

  onFilterChange = obj => {
    const [[key, value]] = Object.entries(obj)

    // search text was updated
    if(key === 'query') {
      return this.setState(() => ({
        query: value
      }))
    }

    // checkboxFilters are a booleans
    if (key in checkboxFilters) {
      return this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          [key]: value.length
            ? value[0]
            : (value ? true : undefined),
        },
      }))
    }

    // All other filters are arrayed
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [key]: xor(this.state.filter[key], value),
        categorize: undefined,
      },
    }))
  }

  openMoreFilters = () => {
    this.setState({ visibleFilter: undefined })
    const filters = omit(this.state.filter, ['grade', 'subject'])
    this.props.modalShow(
      <LessonFilterModal
        onSave={this.search}
        currentState={filters}
        changeFilter={obj => this.onFilterChange(obj)}
        clearFilters={() => this.clearFilters(Object.keys(filters))}
      />
    )
  }

  clearFilters = arr => {
    const resetFilter = arr.reduce((acc, cur) => {
      // Reset filter to initial value
      acc[cur] = initialFilter[cur]
      return acc
    }, {})
    const filter = {
      ...this.state.filter,
      ...resetFilter,
    }

    this.updateUrl({ filter })
  }

  clearAllFilters = () => {
    this.updateUrl({
      filter: {
        ...initialFilter,
        categorize: true,
      },
      query: ''
    })
  }

  setSelectedFilters() {
    const selected = [];
  
    // Include checkbox filters
    for (const key in checkboxFilters) {
      if (key in this.state.filter) {
        if (this.state.filter[key]) {
          selected.push({ field: key, value: checkboxFilters[key] });
        }
      }
    }
  
    // Include other filters and keep additional parameters in the search query
    for (const key in this.state.filter) {
      if (key !== 'categorize' && key !== 'query' && !(key in checkboxFilters)) {
        const currentFilterSet = this.state.filter[key];
        if (currentFilterSet) {
          if (Array.isArray(currentFilterSet)) {
            currentFilterSet.forEach(value => {
              selected.push({ field: key, value });
            });
          }
        }
      }
    }
  
    // Optionally, you can also add the search query as a selected filter
    selected.push({ field: 'query', value: this.state.query });
  
    this.setState({ selected });
  }
  

  removeFilter = ({ field, value }) => {
    // remove given item from state
    const filter =
      field in checkboxFilters
        ? omit(this.state.filter, [field])
        : {
            ...this.state.filter,
            [field]: this.state.filter[field].filter(item => item !== value),
          }
    this.updateUrl({ filter })
  }

  sort = sort => {
    this.updateUrl({ sort })
  }

  changePage = page => {
    if (page > 0) {
      this.updateUrl({ page })
    }
  }

  updateUrl({
    query = this.state.query,
    filter = this.state.filter,
    sort = this.state.sort,
    page = this.state.page,
  }) {
    const obj = this.getDataObj({ query, filter, sort, page })
    const key = qs.stringify(obj, { arrayFormat: 'brackets' })

    // Check to see if we're blocked
    const link = `/lessons?${key}`
    if(lessonSearchLinkReferencesORALessons(link) && !this.props.userCanViewORALessons) {
      this.props.modalShow(<ORARegisterModal routeURL={link}/>)
    } else {
      this.setState({ query, filter, sort, page, key })
      this.props.history.push({
        pathname: '/lessons',
        search: '?' + key,
      })
    }
  }

  getDataObj({ query, sort, page, limit, filter }) {
    return {
      query,
      sort,
      page,
      limit,
      ...filter,
    }
  }

  fetchLessons(state = undefined) {
    state = state || this.state

    // get search object from state
    const obj = this.getDataObj(state)
    const key = qs.stringify(
      {
        ...obj,
        // try not to include the actual categorizedBy field in the page URL, as it's a lot of items, and can be confusing
        categorizedBy: obj.categorize
          ? CATEGORIES.map(c => c.value)
          : undefined,
      },
      { arrayFormat: 'comma' }
    )

    // console.log('lessonsSearchRequest')
    // console.log({ ...obj, key });

    // TODO? need to normalize to lower case?
    this.props.lessonsSearchRequest({ ...obj, key })
    if(state.query) {
      this.props.userAddRecentSearchQuery({ query: state.query })
    }
    this.setSelectedFilters()
  }

  viewLessonsForCategory = (category) => {
    // console.log('viewMore', category);

    const special = {
      'Ari Lessons': {
        coding: ['Ari App']
      },
      'STEAM Kits': {
        products: ['STEAM Kits']
      },
    }

    const newSearch = special[category] || {
      categories: [category]
    }

    // Here, we remove the categorizedBy filtering, which does batch lookup of multiple categories,
    // and substitute filtering by a single category using the "categories" filter
    this.updateUrl({ filter: {
      ...this.state.filter,
      categorize: undefined,
      ...newSearch,
    } })
  }

  viewAriCompatibleLessons = () => {
    this.updateUrl({ filter: {
      ...initialFilter,
      categorize: undefined,
      bots: ['Ari'],
    } })
  }

  render() {

    // Make sure that the shortcut menu can fit with both the expanded and contracted filter buttons...
    const showShortcutMenuAtTop = this.props.browserWidowWidth >= 1400

    return (
      <Layout ref={this.scrollView} isFetching={this.props.isFetching}>
        <Content>
          <View
            style={[
              s.flexRow,
              s.alignCenter,
              showShortcutMenuAtTop ? s.justifyBetween : s.justifyCenter,
              s.zIndex2,
              s.p20,
              s.bShadow,
              s.w100p,
              { height: showShortcutMenuAtTop
                ? 120
                : this.props.isMobile
                  ? 73
                  : 100 }
            ]}
          >
              { showShortcutMenuAtTop && <SearchShortcutsMenu isMobile={this.props.isMobile }/> }
              <View style={[s.flexRow, s.ml24, !showShortcutMenuAtTop && s.alignCenter ]}>
                <SearchFilters
                  filter={this.state.filter}
                  query={this.state.query}
                  visibleFilter={this.state.visibleFilter}
                  onSetVisibleFilter={filter => this.setState({ visibleFilter: filter })}
                  onFilterChange={this.onFilterChange}
                  onSave={this.search}
                  moreFilters={this.openMoreFilters}
                  recentQueries={this.props.recentQueries}
                  popupMenuTop={showShortcutMenuAtTop ? 18 : 8 }
                />
              </View>
              { showShortcutMenuAtTop && <View style={[s.w700]}/> }
          </View>

          <View style={[s.mt20, s.mb120]}>

            { !showShortcutMenuAtTop && <SearchShortcutsMenu isMobile={this.props.isMobile}/> }

            <View style={[s.mt16, s.mx20]}>
              <FilterChoices
                selected={this.state.selected}
                isCategorizing={!!this.state.filter.categorize}
                clearAll={this.clearAllFilters}
                removeFilter={this.removeFilter}
                searchTotal={this.props.searchTotal}
                query={this.state.query}
              />
            </View>

            { !!this.state.filter.categorize && <SearchResultsByCategory 
                categorizedResults={this.props.categorizedResults}
                saved={this.props.saved}
                onViewMore={this.viewLessonsForCategory}
                isDesktop={this.props.isDesktop}
              />
            }

            { !this.state.filter.categorize && (this.isAriQuery() 
              ? <SearchResultsByApp
                  lessons={this.props.results}
                  saved={this.props.saved}
                  isDesktop={this.props.isDesktop}
                  noResult={(<NoSearchResults/>)}
                  onViewMore={this.viewAriCompatibleLessons}
                />
              : <SearchResults
                lessons={this.props.results}
                total={this.props.searchTotal}
                saved={this.props.saved}
                noResult={(<NoSearchResults/>)}
              />)
            }

            { this.props.searchTotal > this.state.limit && !this.state.filter.categorize && (
              <View style={[s.alignEnd, s.pb20, s.pr50]}>
                <Pagination
                  pageAmount={this.getPageAmount()}
                  currentPage={parseInt(this.state.page)}
                  changePage={this.changePage}
                />
              </View>
            )}
          </View>

          { !this.props.isMobile && !!this.state.visibleFilter && (
            <DimmedOverlay
              top={ showShortcutMenuAtTop ? 120 : 100 }
              onPress={() => { this.setState({ visibleFilter: undefined }) }}
            />
          )}

        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.lessons.isFetching,
    results: lessonsResultsSelector(state),
    categorizedResults: lessonsCategorizedResultsSelector(state),
    searchTotal: lessonsSearchTotalSelector(state),
    recentQueries: userRecentLessonSearchesSelector(state),
    userCanViewORALessons: userCanViewORALessonsSelector(state),
    saved: state.lessons.saved,
  }
}

export default withDeviceHook(connect(mapStateToProps, {
  lessonsSearchRequest: lessonsActions.searchRequest,
  lessonsSavedRequest: lessonsActions.listSavedRequest,
  userAddRecentSearchQuery: userAddRecentSearchQuery,
  modalShow,
})(LessonsSearch))
