import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { xor } from 'lodash'
import { BOTS, CODING_STYLES, PRODUCTS } from 'lib/utils.js'
import { Button } from 'components/Form'
import { ModalFrame } from 'components/modal'
import { CheckBoxSetFilter, ToggleFilter } from './LessonsFilterModalEntry'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

class LessonsFilter extends React.Component {
  state = {
    filter: {
      ...this.props.currentState,
    },
  }

  onFilterChange = obj => {
    const [[key, value]] = Object.entries(obj)

    const filterVal = value === true || value === false
      ? (value ? true : undefined)
      : xor(this.state.filter[key], value)

    const filter = {
      ...this.state.filter,
      [key]: filterVal,
    }
    this.setState({ filter })
    return this.props.changeFilter(obj)
  }

  render() {
    const numCheckboxColumns = this.props.isMobile ? 2 : 3

    return (
      <ModalFrame title="Filters" size="medium">
        <View style={[s.pb8, s.mb32]}>
          <CheckBoxSetFilter 
            title='Coding Method'
            entries={CODING_STYLES}
            values={this.state.filter.coding}
            valueName='coding'
            onFilterChange={this.onFilterChange}
            numColumns={numCheckboxColumns}
          />
          <CheckBoxSetFilter 
            title='Robot'
            entries={BOTS}
            values={this.state.filter.bots}
            valueName='bots'
            onFilterChange={this.onFilterChange}
            numColumns={numCheckboxColumns}
          />
          <CheckBoxSetFilter 
            title='Product Lessons'
            entries={PRODUCTS}
            values={this.state.filter.products}
            valueName='products'
            onFilterChange={this.onFilterChange}
            numColumns={numCheckboxColumns}
          />
          <ToggleFilter 
            title='Video Lessons'
            value={this.state.filter.has_video}
            onFilterChange={(newValue) => this.onFilterChange({ has_video: newValue }) }
          />
          <ToggleFilter 
            title='Mini Lessons'
            text='Lessons that are one-pagers'
            value={this.state.filter.is_mini}
            onFilterChange={(newValue) => this.onFilterChange({ is_mini: newValue }) }
          />
        </View>
        <View style={[s.flexRow, s.justifyBetween, s.pt20, s.bGray, s.bTop1]}>
          <Button
            text="Clear All"
            role="fifth"
            onPress={this.props.clearFilters}
            style={[s.mx8]}
          />
          <Button
            text="Show Lessons"
            role ="fourth"
            onPress={() => this.props.onSave()}
            style={[s.mx8, s.mr20]}
          />
        </View>
      </ModalFrame>
    )
  }
}

const LessonsFilterWrapper = (props) => {
  const { isDesktop, isMobile, isTablet } = useDetectDeviceType();

  const commonStyles = {
    marginLeft: isMobile ? 20 : (isTablet ? 40 : 'auto'),
    marginRight: isMobile ? 20 : (isTablet ? 40 : 'auto'),
    marginTop: isMobile ? 20 : (isTablet ? 40 : (isDesktop ? 40 : 0)),
    height: isMobile ? 'calc(100vh - 100px)' : 'auto',
    overflowY: isMobile || isTablet ? 'scroll' : 'visible',
    display: isDesktop ? 'flex' : 'block',
    justifyContent: isDesktop ? 'center' : 'flex-start',
    alignItems: isDesktop ? 'center' : 'flex-start',
    borderRadius: 28,
  };

  return (
    <div style={commonStyles}>
      <LessonsFilter {...props} isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet} />
    </div>
  );
};

// export default connect(null, null)(LessonsFilter)
export default connect(null, null)(LessonsFilterWrapper)
